import {
  ApolloQueryResult,
  gql,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Card,
  DynamicIcon,
  Heading,
  Inline,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import {
  BoostRecipeParameterType,
  BoostSourceType,
  useCreateBoostRecipe,
  useUpdateBoostRecipe,
} from '@spaceship-fspl/graphql';
import { WebAppBoostRecipeSetup } from '@spaceship-fspl/graphql/src/__generated__/WebAppBoostRecipeSetup';
import {
  WebAppRecipePostcodeLookup,
  WebAppRecipePostcodeLookupVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppRecipePostcodeLookup';
import { ExternalRoutes, maskBankAccountNumber } from '@spaceship-fspl/helpers';
import { fontFamily, fontWeight } from '@spaceship-fspl/styles';
import { getBoostRecipeFormParts } from '@spaceship-fspl/voyager';
import { InlineError } from 'components/inline-error';
import {
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormExtraButton,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import { addRumError } from 'helpers/monitoring';
import { voyagerPortfolios } from 'helpers/portfolios';
import { commonValidationRules } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { get, NestedValue, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { BoostDropdown, BoostTextInput } from './components/inputs';
import { ContentLayout } from './components/layout';
import { RemoveBoostModal } from './components/remove-boost-modal';

interface BoostsRecipeSetupProps {
  location: {
    state:
      | { type: 'create-recipe'; templateId: string }
      | { type: 'edit-recipe'; recipeId: string };
  };
}

type FormFields = Partial<{
  saverProductInstanceId: string;
  postcode: string;
  parameters: NestedValue<{
    [k: string]: string;
  }>;
}>;

export const BoostsRecipeSetup: React.FC<
  React.PropsWithChildren<RouteComponentProps<BoostsRecipeSetupProps>>
> = (props) => {
  const createTemplateId =
    (props.location?.state?.type === 'create-recipe' &&
      props.location?.state?.templateId) ||
    '';
  const editRecipeId =
    (props.location?.state?.type === 'edit-recipe' &&
      props.location?.state?.recipeId) ||
    '';
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRemoveBoostModal, setShowRemoveBoostModal] = useState(false);
  const [createBoostRecipe] = useCreateBoostRecipe();
  const [updateBoostRecipe] = useUpdateBoostRecipe();
  const notifications = useNotifications();
  const intercom = useIntercom();
  const client = useApolloClient();
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      saverProductInstanceId: '',
      postcode: '',
    },
  });
  const formErrorMessages: Array<string> = [];

  if (form.formState.errors?.postcode?.message) {
    formErrorMessages.push(form.formState.errors.postcode.message.toString());
  }

  Object.keys(form.formState.errors?.parameters ?? {}).forEach((paramName) => {
    const message = get(
      form.formState.errors,
      `parameters.${paramName}`,
    )?.message;
    if (!!message && !formErrorMessages.includes(message)) {
      formErrorMessages.push(message.toString());
    }
  });

  const resp = useQuery<WebAppBoostRecipeSetup>(
    gql`
      query WebAppBoostRecipeSetup(
        $templateId: ID!
        $templateIdProvided: Boolean!
        $recipeId: ID!
        $recipeIdProvided: Boolean!
      ) {
        boostRecipeTemplate(id: $templateId) @include(if: $templateIdProvided) {
          id
          name
          iconName
          sourceType
          description
          parameters {
            id
            name
            type
          }
        }
        boostRecipe(id: $recipeId) @include(if: $recipeIdProvided) {
          id
          name
          saverProductInstance {
            id
          }
          description
          iconName
          source {
            ... on WeatherStation {
              id
              postcode
            }
            ... on BasiqConnection {
              id
            }
          }
          parameters {
            id
            name
            type
            value
          }
        }
        contact {
          id
          address {
            postcode
          }
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            saverProductInstances {
              id
              portfolio
              investments {
                id
                summary {
                  id
                  audBalance
                }
              }
            }
            basiqConnections {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        templateIdProvided: !!createTemplateId,
        templateId: createTemplateId,
        recipeIdProvided: !!editRecipeId,
        recipeId: editRecipeId,
      },
      onError: () => {
        notifications.popToast({
          level: 'error',
          message: 'Oops, there was a problem loading boost recipes.',
        });
        navigate(Routes.BOOSTS_RECIPE_SELECT);
      },
      onCompleted: (data) => {
        if (props.location?.state?.type === 'create-recipe') {
          form.setValue('postcode', data.contact.address?.postcode, {
            shouldValidate: true,
          });
          form.setValue(
            'saverProductInstanceId',
            data.contact.account?.saverProductInstances?.[0]?.id ?? '',
            { shouldValidate: true },
          );
        } else {
          if (data.boostRecipe?.source?.__typename === 'WeatherStation') {
            form.setValue('postcode', data.boostRecipe.source.postcode, {
              shouldValidate: true,
            });
          }

          form.setValue(
            'saverProductInstanceId',
            data.boostRecipe?.saverProductInstance?.id ?? '',
            { shouldValidate: true },
          );

          data.boostRecipe?.parameters?.forEach((p) => {
            form.setValue(
              `parameters.${p.name}` as 'parameters.${string}',
              p.value,
              {
                shouldValidate: true,
              },
            );
          });
        }
      },
    },
  );

  const queryPostcode = async (
    postcode: string,
  ): Promise<ApolloQueryResult<WebAppRecipePostcodeLookup>> => {
    return await client.query<
      WebAppRecipePostcodeLookup,
      WebAppRecipePostcodeLookupVariables
    >({
      variables: { postcode },
      query: gql`
        query WebAppRecipePostcodeLookup($postcode: String!) {
          weatherStation(postcode: $postcode) {
            id
            name
            postcode
          }
        }
      `,
    });
  };

  const account = resp.data?.contact?.account;
  const accountNumber = account?.activeBankAccount?.accountNumber;
  const hasBasiqConnections = (account?.basiqConnections ?? []).length > 0;
  const template = resp.data?.boostRecipeTemplate;
  const recipe = resp.data?.boostRecipe;
  const description = template?.description ?? recipe?.description ?? '';
  const portfolios = account?.saverProductInstances ?? [];
  const portfolioOptions =
    portfolios.map((spi) => ({
      label: voyagerPortfolios[spi.portfolio].name,
      value: spi.id,
    })) ?? [];

  let isWeatherBoost = false;
  let isBasiqBoost = false;
  switch (template?.sourceType ?? recipe?.source?.__typename) {
    case 'WeatherStation':
    case BoostSourceType.WEATHER:
      isWeatherBoost = true;
      break;
    case 'BasiqConnection':
    case BoostSourceType.BASIQ_CONNECTION:
      isBasiqBoost = true;
  }

  // Important to trim _typename here otherwise later mutations
  // will fail.
  const standardParameters = useMemo(
    () =>
      template?.parameters?.map((parameter) => {
        const { __typename, id, ...rest } = parameter;
        return rest;
      }) ??
      recipe?.parameters?.map((parameter) => {
        const { __typename, id, ...rest } = parameter;
        return rest;
      }) ??
      [],
    [recipe, template],
  );

  const templateData = useMemo(
    () =>
      getBoostRecipeFormParts({
        description,
        parameters: standardParameters,
      }),
    [description, standardParameters],
  );

  useEffect(() => {
    if (!createTemplateId && !editRecipeId) {
      navigate(Routes.BOOSTS_DASHBOARD_HOME);
    }
  }, [createTemplateId, editRecipeId, navigate]);

  const onSubmit = form.handleSubmit(async (values): Promise<void> => {
    const parametersWithValues = standardParameters.map((parameter) => {
      return {
        ...parameter,
        value: values.parameters?.[parameter.name] ?? '',
      };
    });

    if (!values.saverProductInstanceId) {
      addRumError({
        error: 'missing saverProductInstanceId',
        context: {
          reason: `${
            props.location?.state?.type === 'create-recipe' ? 'Create' : 'Edit'
          } boost recipe failed`,
        },
      });
      notifications.popToast({
        level: 'error',
        message:
          'Oops, something went wrong. Contact our customer support team and we’ll get you back on track.',
        cta: {
          message: 'Contact support',
          action: () => intercom.pop(),
        },
      });
      return;
    }

    if (isWeatherBoost) {
      setIsSubmitting(true);

      try {
        if (!values.postcode) {
          throw Error('no postcode submitted');
        }

        const resp = await queryPostcode(values.postcode);
        const stationId = resp.data.weatherStation?.id;
        if (!stationId) {
          notifications.popToast({
            level: 'error',
            message: 'The postcode provided is not supported.',
          });
          return;
        }

        if (props.location?.state.type === 'create-recipe') {
          await createBoostRecipe({
            variables: {
              input: {
                templateId: createTemplateId,
                saverProductInstanceId: values.saverProductInstanceId,
                parameters: parametersWithValues,
                sourceId: stationId,
              },
            },
          });

          navigate(Routes.BOOSTS_RECIPE_SETUP_SUCCESS, {
            state: {
              title: 'We’ve successfully set up your boost',
            },
          });
        } else {
          await updateBoostRecipe({
            variables: {
              input: {
                id: editRecipeId,
                parameters: parametersWithValues,
                sourceId: stationId,
              },
            },
          });

          await navigate(Routes.BOOSTS_DASHBOARD_BOOSTS);

          notifications.popToast({
            level: 'success',
            message: 'You have successfully edited your boost',
          });
        }
      } catch (error) {
        addRumError({ error });
        notifications.popToast({
          level: 'error',
          message:
            'Oops, we ran into a problem setting up your boost. Contact our customer support team and we’ll get you back on track.',
          cta: {
            message: 'Contact support',
            action: () => intercom.pop(),
          },
        });
      } finally {
        setIsSubmitting(false);
      }
    } else if (isBasiqBoost) {
      const basiqRoute = hasBasiqConnections
        ? Routes.BOOSTS_BASIQ_SELECT
        : Routes.BOOSTS_BASIQ_INSTITUTIONS;

      if (props.location?.state.type === 'create-recipe') {
        navigate(basiqRoute, {
          state: {
            type: 'create-new-recipe',
            templateId: createTemplateId,
            saverProductInstanceId: values.saverProductInstanceId,
            parameters: parametersWithValues,
          },
        });
      } else {
        navigate(basiqRoute, {
          state: {
            type: 'edit-existing-recipe',
            recipeId: editRecipeId,
            parameters: parametersWithValues,
          },
        });
      }
    }
  });

  return (
    <PageContainer>
      <ContentLayout>
        <PageHeading title="Confirm your boost" />

        {resp.loading ? (
          <Card
            marginTop={{ xs: 'sm', lg: 'lg' }}
            padding="lg"
            height={200}
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxShadow="sm"
          >
            <Spinner />
          </Card>
        ) : (
          <form onSubmit={onSubmit}>
            <Card
              marginTop={{ xs: 'sm', lg: 'lg' }}
              marginBottom="md"
              padding={{ xs: 'md', lg: 'lg' }}
              boxShadow="sm"
            >
              <Stack spaceY="md">
                <Stack spaceY={{ xs: 'xxs', lg: 'sm' }}>
                  <Inline alignY="center" spaceX="xxs">
                    <DynamicIcon
                      color="neutral.100"
                      name={template?.iconName ?? recipe?.iconName}
                    />

                    <Heading variant={5} isBold={true}>
                      {template?.name ?? recipe?.name ?? ''}
                    </Heading>
                  </Inline>

                  <StyledTemplateContainer>
                    {templateData.parts.map((p, i) => {
                      if (p.slice(0, 2) == '{{' && p.slice(-2) == '}}') {
                        const paramName = p.slice(2, -2);

                        const parameterInputs = templateData.inputs
                          .map((input) => {
                            if (input.name == paramName) {
                              switch (input.type) {
                                case 'portfolio':
                                  if (
                                    portfolioOptions.length > 1 &&
                                    props.location?.state?.type ===
                                      'create-recipe'
                                  ) {
                                    return (
                                      <Box
                                        display="inline-flex"
                                        marginRight="xxs"
                                        key={input.name}
                                      >
                                        <BoostDropdown
                                          placeholder="portfolio"
                                          options={portfolioOptions}
                                          {...form.register(
                                            'saverProductInstanceId',
                                            {
                                              required: 'Portfolio is required',
                                            },
                                          )}
                                        />
                                      </Box>
                                    );
                                  } else {
                                    return (
                                      <Fragment key={input.name}>
                                        {props.location?.state?.type ===
                                        'create-recipe'
                                          ? portfolioOptions[0]?.label
                                          : portfolioOptions.find(
                                              (o) =>
                                                o.value ===
                                                recipe?.saverProductInstance
                                                  ?.id,
                                            )?.label}{' '}
                                        <input
                                          type="hidden"
                                          {...form.register(
                                            'saverProductInstanceId',
                                          )}
                                        />
                                      </Fragment>
                                    );
                                  }

                                case 'postcode': {
                                  return (
                                    <Box
                                      display="inline-flex"
                                      marginRight="xxs"
                                      key={input.name}
                                    >
                                      <BoostTextInput
                                        placeholder="postcode"
                                        maxLength={4}
                                        hasErrors={
                                          !!form.formState.errors?.postcode
                                            ?.message
                                        }
                                        {...form.register('postcode', {
                                          required: 'Postcode is required',
                                          validate: async (v?: string) => {
                                            if (!v) {
                                              return 'Postcode is required';
                                            }
                                            if (v.length !== 4) {
                                              return 'Postcode must be 4 characters long';
                                            }

                                            try {
                                              const resp =
                                                await queryPostcode(v);
                                              if (resp.data.weatherStation) {
                                                return true;
                                              }

                                              return 'Unsupported postcode provided';
                                            } catch {
                                              return 'We were unable to verify your postcode';
                                            }
                                          },
                                        })}
                                      />
                                    </Box>
                                  );
                                }

                                case BoostRecipeParameterType.AUD_AMOUNT: {
                                  return (
                                    <Box
                                      display="inline-flex"
                                      marginRight="xxs"
                                      key={input.name}
                                    >
                                      <BoostTextInput
                                        placeholder="amount"
                                        hasErrors={
                                          !!get(
                                            form.formState.errors,
                                            `parameters.${paramName}`,
                                          )?.message
                                        }
                                        isCurrency={true}
                                        maxLength={5}
                                        {...form.register(
                                          `parameters.${paramName}` as 'parameters.${string}',
                                          {
                                            validate: (value?: string) => {
                                              if (!value) {
                                                return 'Amount is required';
                                              }
                                              return commonValidationRules.atLeastOneCent.validate(
                                                value,
                                                false,
                                              );
                                            },
                                          },
                                        )}
                                      />
                                    </Box>
                                  );
                                }
                                default: {
                                  return null;
                                }
                              }
                            }
                            return null;
                          })
                          .filter(Boolean);

                        return parameterInputs[0] ?? null;
                      } else {
                        return <Fragment key={i}>{p}</Fragment>;
                      }
                    })}
                  </StyledTemplateContainer>
                </Stack>

                {formErrorMessages.length > 0 && (
                  <Stack spaceY="xxxs">
                    {formErrorMessages.map((msg, idx) => (
                      <InlineError message={msg} key={idx} />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Card>

            <Text variant={3} color="neutral.085">
              Your Boosts investments will be transferred from your bank account
              ending in {maskBankAccountNumber(accountNumber)}.
              <br />
              <br />
              By clicking ‘Continue’, you acknowledge this investment will be
              processed in accordance with our{' '}
              <TextLink
                color="indigo.070"
                href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                target="_blank"
              >
                Direct Debit Request
              </TextLink>{' '}
              and{' '}
              <TextLink
                color="indigo.070"
                href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                target="_blank"
              >
                Direct Debit Request Service Agreement
              </TextLink>
              .
            </Text>

            <PageFormButtonContainer>
              <PageFormContinueButton
                isLoading={isSubmitting}
                trackingProperties={{
                  name: 'boosts_recipe_setup_continue',
                }}
              />

              {!!editRecipeId && (
                <PageFormExtraButton
                  isDisabled={isSubmitting}
                  onClick={() => {
                    setShowRemoveBoostModal(true);
                  }}
                  trackingProperties={{
                    name: 'boosts_recipe_setup_remove_boost',
                  }}
                >
                  Remove boost
                </PageFormExtraButton>
              )}

              <PageFormGoBackButton
                isDisabled={isSubmitting}
                trackingProperties={{ name: 'boosts_recipe_setup_go_back' }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </PageFormButtonContainer>
          </form>
        )}
      </ContentLayout>

      {!!editRecipeId && (
        <RemoveBoostModal
          showModal={showRemoveBoostModal}
          onCloseModal={() => {
            setShowRemoveBoostModal(false);
          }}
          recipeId={editRecipeId}
        />
      )}
    </PageContainer>
  );
};

const StyledTemplateContainer = styled.div`
  ${fontFamily('heading')}
  ${fontWeight('bold')}
  font-size: 24px;
  line-height: 48px;
`;
