import { gql, useQuery } from '@apollo/client';
import { navigate, Redirect, RouteComponentProps, Router } from '@reach/router';
import {
  useCanReadSaver,
  useCanReadSuper,
  useIsAuthenticated,
  useIsUserWithNoProduct,
} from '@spaceship-fspl/auth';
import { useBoolean } from '@spaceship-fspl/dynamic-config';
import { TaxResidenceStatus } from '@spaceship-fspl/graphql';
import { WebAppDashboardRoutes } from '@spaceship-fspl/graphql/src/__generated__/WebAppDashboardRoutes';
import { WebAppVoyagerRoutes } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerRoutes';
import { useIdentify } from '@spaceship-fspl/tracking';
import { GeneralHeader } from 'components/general-header';
import {
  AuthenticatedNav,
  SuperMatchNavRoutes,
  SuperOnboardingNavRoutes,
  VoyagerAddPortfolioNavRoutes,
  VoyagerOnboardingNavRoutes,
  VoyagerSignupNavRoutes,
} from 'components/navigation';
import { UpdateBankAccountSubmissionProvider } from 'contexts/bank-account-verification';
import { LoginProvider } from 'contexts/login-provider';
import {
  MfaDashboardRegistrationProvider,
  MfaRegistrationMandatoryProvider,
} from 'contexts/mfa-registration-provider';
import { ResetPasswordProvider } from 'contexts/saver/onboarding';
import { SuperEmployerContributionProvider } from 'contexts/super/employer-contribution';
import {
  SuperAddInvestmentOptionProvider,
  SuperChangeInvestmentOptionProvider,
} from 'contexts/super/investment-option';
import { DynamicConfigKeys, FeatureFlagKeys } from 'helpers/dynamic-config';
import { useDeviceFingerprint } from 'helpers/hooks/use-device-fingerprint';
import { useNavigateToSuperCrossSellFromReferral } from 'helpers/hooks/use-navigate-to-super-cross-sell-from-referral';
import { Account } from 'pages/account';
import { ChangePassword } from 'pages/account/change-password';
import { AccountConfirmContactDetails } from 'pages/account/confirm-contact-details';
import { AppDisabled } from 'pages/app-disabled';
import { BoostsDebug } from 'pages/boosts-debug';
import { Login } from 'pages/login';
import { MfaLoginChallenge } from 'pages/mfa-login-challenge';
import {
  MfaRegistration,
  MfaRegistrationIntro,
  MfaRegistrationSuccess,
} from 'pages/mfa-registration';
import { MfaTooManyAttempts } from 'pages/mfa-too-many-attempts';
import { SuperComingSoon, VoyagerComingSoon } from 'pages/mobile-apps';
import { MoneyDepositConfirm } from 'pages/money/deposit-confirm';
import { MoneyDepositSuccess } from 'pages/money/deposit-success';
import { MoneyWithdrawConfirm } from 'pages/money/withdraw-confirm';
import { MoneyWithdrawSuccess } from 'pages/money/withdraw-success';
import { NovaEtfs } from 'pages/nova/etfs';
import { NovaStocks } from 'pages/nova/stocks';
import { PageNotFound } from 'pages/page-not-found';
import { PasswordReset } from 'pages/password-reset';
import { PasswordResetNewPassword } from 'pages/password-reset-new-password';
import { PasswordResetVerify } from 'pages/password-reset-verify';
import { PasswordResetVerifyCognito } from 'pages/password-reset-verify-cognito';
import { Routes } from 'pages/routes';
import { SecurityCheck } from 'pages/security-check';
import { SuperBalance } from 'pages/super/balance';
import { SuperConsolidateOptions } from 'pages/super/consolidate-options';
import { SuperDashboard } from 'pages/super/dashboard';
import { SuperFhss } from 'pages/super/fhss';
import { SuperImportantDocuments } from 'pages/super/important-documents';
import { SuperUpdateTfn } from 'pages/super/tfn';
import { SuperTransactions } from 'pages/super/transactions';
import { SuperUnitPrice } from 'pages/super/unit-price';
import { SuperContributions } from 'pages/super-contributions';
import { SuperEmployerContribution } from 'pages/super-contributions/employer';
import { SuperEmployerContributionSuccess } from 'pages/super-contributions/employer-success';
import { SuperVoluntaryContribution } from 'pages/super-contributions/voluntary';
import { SuperLinkError } from 'pages/super-link/error';
import { SuperLinkLogin } from 'pages/super-link/login';
import { SuperLinkMismatch } from 'pages/super-link/mismatch';
import { SuperLinkResetPassword } from 'pages/super-link/reset-password';
import { SuperLinkResetPasswordNew } from 'pages/super-link/reset-password-new';
import { SuperLinkSuccess } from 'pages/super-link/success';
import { SuperReferralsShareCode } from 'pages/super-referrals-share-code';
import { SuperSelectInvestmentOption } from 'pages/super-select-investment-option';
import { SuperSelectInvestmentOptionSuccess } from 'pages/super-select-investment-option-success';
import {
  VoyagerAddTaxResidencyCountries,
  VoyagerUpdateTaxResidencyCountries,
} from 'pages/tax-residency';
import { VoyagerTaxResidencyIntro } from 'pages/tax-residency/intro';
import { TaxResidencyNotEditable } from 'pages/tax-residency/not-editable';
import {
  VoyagerAddTaxResidencyPrimaryAddress,
  VoyagerUpdateTaxResidencyPrimaryAddress,
} from 'pages/tax-residency/primary-address';
import {
  VoyagerAddTaxResidencyTaxGroup,
  VoyagerUpdateTaxResidencyTaxGroup,
} from 'pages/tax-residency/tax-group';
import { UsInvestingWarning } from 'pages/tax-residency/us-investing-warning';
import { VoyagerDashboard } from 'pages/voyager-dashboard';
import { VoyagerDeposit } from 'pages/voyager-deposit';
import { VoyagerDepositConfirm } from 'pages/voyager-deposit-confirm';
import { VoyagerDepositSuccess } from 'pages/voyager-deposit-success';
import { VoyagerInvestOptions } from 'pages/voyager-invest-options';
import { VoyagerInvestmentPlan } from 'pages/voyager-investment-plan';
import { VoyagerInvestmentPlanPortfolios } from 'pages/voyager-investment-plan-portfolios';
import { VoyagerInvestmentPlanSuccess } from 'pages/voyager-investment-plan-success';
import { VoyagerPortfolio } from 'pages/voyager-portfolio';
import { VoyagerAboutEarthPortfolio } from 'pages/voyager-portfolio/about-earth';
import { VoyagerReferrals } from 'pages/voyager-referrals';
import { VoyagerReferralsEnterCode } from 'pages/voyager-referrals-enter-code';
import { VoyagerTaxFileNumber } from 'pages/voyager-tfn';
import { VoyagerTaxFileNumberExemption } from 'pages/voyager-tfn-exemption';
import { VoyagerTaxFileNumberLearnMore } from 'pages/voyager-tfn-learn-more';
import { VoyagerTransactions } from 'pages/voyager-transactions';
import { VoyagerUnitPrice } from 'pages/voyager-unit-price';
import { VoyagerWithdraw } from 'pages/voyager-withdraw';
import { VoyagerWithdrawConfirm } from 'pages/voyager-withdraw-confirm';
import { VoyagerWithdrawSuccess } from 'pages/voyager-withdraw-success';
import React, { FC, memo, useEffect } from 'react';

import { MoneyDashboard } from '../pages/money/dashboard';
import { MoneyDeposit } from '../pages/money/deposit';
import { MoneyWithdraw } from '../pages/money/withdraw';
import { Boosts } from './boosts';
import {
  AuthenticatedRoutes,
  BankAccountRoutes,
  FeatureFlaggedRoute,
  navigateAwayWhenAuthenticated,
  NestedRoutes,
} from './helpers';
import { Onboarding } from './onboarding';
import { Signup } from './signup';
import { SuperMatchConsolidate } from './super-match';
import { NavigationTracking } from './tracking';
import { VoyagerPortfolioRegistration } from './voyager-portfolio-registration';

export const Navigation: FC<React.PropsWithChildren> = () => {
  const appDisabled = useBoolean(DynamicConfigKeys.APP_DISABLED, false);
  const identify = useIdentify();
  const fingerprint = useDeviceFingerprint();
  useEffect(() => {
    if (fingerprint) {
      // Anonymous identify
      identify?.(undefined, { contextTraits: { fingerprint } });
    }
  }, [fingerprint, identify]);

  if (appDisabled) {
    return (
      <Router>
        <AppDisabled path="/" default={true} />
      </Router>
    );
  }

  return (
    <>
      <NavigationTracking />
      <Router>
        <GeneralHeader path="login" />
        <GeneralHeader path="login/challenge" isCTAShown={false} />
        <GeneralHeader path="login/too-many-attempts" isCTAShown={false} />
        <GeneralHeader path="signup" />
        <GeneralHeader path="signup/details" isCTAShown={false} />
        <GeneralHeader path="signup/verify-phone/*" isCTAShown={false} />
        <GeneralHeader path="signup/choose-product" isCTAShown={false} />
        <GeneralHeader path="signup/super/onboarding/*" isCTAShown={false} />
        <GeneralHeader path="signup/super/referred" isCTAShown={false} />
        <GeneralHeader path="reset-password/*" />
        <GeneralHeader path="onboarding/" isCTAShown={false} />
        <GeneralHeader path="onboarding/voyager/srm" isCTAShown={false} />
        <GeneralHeader path="onboarding/mobile-apps" isCTAShown={false} />
        <GeneralHeader path="account/details/confirm" isCTAShown={false} />
        <GeneralHeader path="super-link/*" isCTAShown={false} />
        <GeneralHeader path="super/select-investment/*" isCTAShown={false} />
        <GeneralHeader path="voyager/tax-residency/intro" />
        <GeneralHeader path="voyager/tax-residency/add/*" />
        <GeneralHeader path="account/verify-phone-required/*" />
      </Router>
      <VoyagerSignupNavRoutes />
      <VoyagerOnboardingNavRoutes />
      <SuperOnboardingNavRoutes />
      <VoyagerAddPortfolioNavRoutes />
      <SuperMatchNavRoutes />
      <Router>
        <Redirect from="/password-reset/" to="/reset-password/" />
        <Redirect from="/password-reset/verify" to="/reset-password/verify" />
        <Redirect
          from="/password-reset/new-password"
          to="/reset-password/new-password"
        />
        <PasswordResetRoutes path="reset-password">
          <PasswordReset path="/" />
          <PasswordResetVerify path="verify" />
          <PasswordResetNewPassword path="new-password" />
          <PasswordResetVerifyCognito path="super/verify" />
          <PageNotFound default={true} />
        </PasswordResetRoutes>
        <LoginProvider path="login">
          <Login path="/" />
          <MfaLoginChallenge path="/challenge" />
          <MfaTooManyAttempts path="/too-many-attempts" done={Routes.LOGIN} />
        </LoginProvider>

        <Signup path="signup/*" />

        <AuthenticatedRoutes path="/">
          <Onboarding path="onboarding/*" />
          <AccountConfirmContactDetails path="account/details/confirm" />
          <FeatureFlaggedRoute
            path="portfolio/add/*"
            flag={FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED}
            route={VoyagerPortfolioRegistration}
          />
          <SuperAddInvestmentOptionProvider path="super/select-investment">
            <SuperSelectInvestmentOption path="/" />
            <SuperSelectInvestmentOptionSuccess path="/success" />
          </SuperAddInvestmentOptionProvider>
          <SuperMatchConsolidate path="super/supermatch/*" />
          <FeatureFlaggedRoute
            path="super-link"
            flag={FeatureFlagKeys.SUPER_CROSS_SELL}
          >
            <SuperLinkLogin path="/" />
            <SuperLinkResetPassword path="reset-password" />
            <SuperLinkResetPasswordNew path="reset-password/new" />
            <SuperLinkMismatch path="mismatch" />
            <SuperLinkError path="error" />
            <SuperLinkSuccess path="success" />
            <PageNotFound default={true} />
          </FeatureFlaggedRoute>
          <VoyagerTaxResidencyIntro path="voyager/tax-residency/intro" />
          <VoyagerAddTaxResidencyTaxGroup path="voyager/tax-residency/add" />
          <VoyagerAddTaxResidencyCountries path="voyager/tax-residency/add/countries" />
          <VoyagerAddTaxResidencyPrimaryAddress path="voyager/tax-residency/add/primary-address" />
          <MfaRegistrationMandatoryProvider path="/account/verify-phone-required">
            <MfaRegistrationIntro path="/intro" />
            <MfaRegistration path="/" />
            <MfaTooManyAttempts path="/too-many-attempts" />
            <MfaRegistrationSuccess path="/success" />
          </MfaRegistrationMandatoryProvider>

          <AuthenticatedNav path="/">
            <RedirectToDashboard path="/" />
            <VoyagerRoutes path="voyager">
              <VoyagerComingSoon path="/coming-soon" />
              <FeatureFlaggedRoute
                path="/boosts-debug"
                flag={FeatureFlagKeys.BOOSTS_DEBUG}
                route={BoostsDebug}
              />
              <VoyagerUnitPrice path="unit-price" />
              <VoyagerUnitPrice path="unit-price/:productId" />
              <PageNotFound path="portfolio" />
              <VoyagerPortfolio path="portfolio/:productId/" />
              <VoyagerPortfolio path="portfolio/:productId/:instrumentId" />
              <VoyagerAboutEarthPortfolio path="portfolio/about-earth" />

              <VoyagerUpdateTaxResidencyTaxGroup path="tax-residency" />
              <VoyagerUpdateTaxResidencyCountries path="tax-residency/countries" />
              <VoyagerUpdateTaxResidencyPrimaryAddress path="tax-residency/primary-address" />
              <UsInvestingWarning path="tax-residency/us-investing-warning" />
              <TaxResidencyNotEditable path="tax-residency/not-editable" />

              <VoyagerTransactions path="transactions" />
              <VoyagerTransactions path="transactions/:productId" />
              <VoyagerWithdraw path="withdraw" />
              <VoyagerWithdraw path="withdraw/:productId" />
              <VoyagerWithdrawConfirm path="withdraw/confirm/:productId" />
              <VoyagerWithdrawSuccess path="withdraw/success/:productId" />
              <VoyagerTaxFileNumber path="tax-file-number" />
              <VoyagerTaxFileNumber path="tax-file-number/:productId" />
              <VoyagerTaxFileNumberExemption path="tax-file-number/exemption/:productId" />
              <VoyagerTaxFileNumberLearnMore path="tax-file-number/learn-more" />
              <NestedRoutes path="referrals">
                <VoyagerReferrals path="/" />
                <VoyagerReferralsEnterCode path="enter-code" />
              </NestedRoutes>
              <VoyagerInvestmentPages path="invest/*" />
              <VoyagerDashboard path="/" />
              <VoyagerDashboard path=":productId" />
              <PageNotFound default={true} />
            </VoyagerRoutes>
            <SuperRoutes path="/super">
              <SuperDashboard path="/" />
              <SuperBalance path="/balance" />
              <SuperUnitPrice path="/unit-price" />
              <SuperTransactions path="/transactions" />
              <SuperImportantDocuments path="/important-documents" />
              <SuperUpdateTfn path="/tax-file-number" />
              <SuperChangeInvestmentOptionProvider path="/change-investment">
                <SuperSelectInvestmentOption path="/" />
                <SuperSelectInvestmentOptionSuccess path="/success" />
              </SuperChangeInvestmentOptionProvider>
              <NestedRoutes path="contributions">
                <SuperContributions path="/" />
                <SuperVoluntaryContribution path="/voluntary" />
                <SuperEmployerContributionProvider path="/employer">
                  <SuperEmployerContribution path="/" />
                  <SuperEmployerContributionSuccess path="success" />
                </SuperEmployerContributionProvider>
              </NestedRoutes>
              <SuperConsolidateOptions path="consolidate" isSignup={false} />
              <SuperReferralsShareCode path="referrals" />
              <SuperComingSoon path="/coming-soon" />
              <SuperComingSoon
                path="/consolidate/coming-soon"
                variant="supermatch"
              />
              <Redirect from="/fhss/coming-soon" to="/super/fhss" />
              <FeatureFlaggedRoute
                path="/fhss"
                flag={FeatureFlagKeys.FHSS_ENABLED}
                route={SuperFhss}
              />
            </SuperRoutes>
            <NovaRoutes path="/etfs">
              <NovaEtfs path="/" />
            </NovaRoutes>
            <NovaRoutes path="/stocks">
              <NovaStocks path="/" />
            </NovaRoutes>
            <MoneyRoutes path="money">
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/"
                route={MoneyDashboard}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/withdraw"
                route={MoneyWithdraw}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/withdraw/confirm"
                route={MoneyWithdrawConfirm}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/withdraw/success"
                route={MoneyWithdrawSuccess}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/add"
                route={MoneyDeposit}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/add/confirm"
                route={MoneyDepositConfirm}
              />
              <FeatureFlaggedRoute
                flag={FeatureFlagKeys.MONEY_DAY_ONE_ENABLED}
                path="/add/success"
                route={MoneyDepositSuccess}
              />
              <Redirect from="/*" to="money" noThrow />
            </MoneyRoutes>
            <NestedRoutes path="account">
              <Redirect from="/" to={Routes.ACCOUNT_USER_DETAILS} noThrow />
              <Account path="/details/:section" section="" />

              <MfaDashboardRegistrationProvider path="/verify-phone">
                <MfaRegistration path="/" />
                <MfaTooManyAttempts
                  path="/too-many-attempts"
                  done={Routes.VOYAGER_DASHBOARD}
                />
                <MfaRegistrationSuccess path="/success" />
              </MfaDashboardRegistrationProvider>
              <FeatureFlaggedRoute
                path="/security-check"
                flag={FeatureFlagKeys.SECURITY_CHECK_ENABLED}
                route={SecurityCheck}
              />
              <UpdateBankAccountSubmissionProvider path="bank-account">
                <BankAccountRoutes path="/*" />
              </UpdateBankAccountSubmissionProvider>
              <FeatureFlaggedRoute
                path="/change-password"
                flag={FeatureFlagKeys.CHANGE_PASSWORD_ENABLED}
                route={ChangePassword}
              />
              <PageNotFound default={true} />
            </NestedRoutes>
          </AuthenticatedNav>
          <PageNotFound default={true} />
        </AuthenticatedRoutes>
        <PageNotFound default={true} />
      </Router>
    </>
  );
};

const MoneyRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  children,
}) => {
  return <>{children}</>;
};

const RedirectToDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isAuthenticated = useIsAuthenticated();
  const isUserWithNoProduct = useIsUserWithNoProduct();
  const canReadSaver = useCanReadSaver();

  const resp = useQuery<WebAppDashboardRoutes>(
    gql`
      query WebAppDashboardRoutes {
        contact {
          id
          phoneNumberVerificationRequired
        }
      }
    `,
    { skip: !isAuthenticated },
  );

  const phoneNumberVerificationRequired =
    resp.data?.contact.phoneNumberVerificationRequired;

  useEffect(() => {
    if (resp.loading) {
      return;
    }

    if (isAuthenticated) {
      if (isUserWithNoProduct) {
        navigate(Routes.RESUME_ONBOARDING, { replace: true });
      } else if (phoneNumberVerificationRequired) {
        navigate(Routes.ACCOUNT_MFA_REGISTRATION_REQUIRED_INTRO, {
          replace: true,
        });
      } else if (canReadSaver) {
        navigate(Routes.VOYAGER_DASHBOARD, { replace: true });
      } else {
        navigate(Routes.SUPER_DASHBOARD, { replace: true });
      }
    }
  }, [
    resp.loading,
    isAuthenticated,
    canReadSaver,
    isUserWithNoProduct,
    phoneNumberVerificationRequired,
  ]);

  return null;
};

const PasswordResetRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = navigateAwayWhenAuthenticated()(
  memo(function PasswordResetRoutesInner({ children }) {
    return <ResetPasswordProvider>{children}</ResetPasswordProvider>;
  }),
);

interface VoyagerRoutesProps {
  location: {
    state: {
      continueToSuperCrossSell?: boolean;
    };
  };
}

const VoyagerRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerRoutesProps>>
> = memo(({ children, location }) => {
  const canReadSaver = useCanReadSaver();
  const canReadSuper = useCanReadSuper();
  const isUserWithNoProduct = useIsUserWithNoProduct();
  const continueToSuperCrossSell =
    location?.state?.continueToSuperCrossSell || false;
  const navigateToSuperCrossSell = useNavigateToSuperCrossSellFromReferral();

  const resp = useQuery<WebAppVoyagerRoutes>(gql`
    query WebAppVoyagerRoutes {
      contact {
        id
        taxResidenceStatus
      }
    }
  `);
  const taxResidenceStatus = resp.data?.contact.taxResidenceStatus;

  useEffect(() => {
    if (!resp.loading) {
      switch (true) {
        case isUserWithNoProduct:
          navigate(Routes.RESUME_ONBOARDING);
          break;
        case continueToSuperCrossSell && !canReadSuper:
          navigateToSuperCrossSell();
          break;
        case canReadSaver &&
          taxResidenceStatus === TaxResidenceStatus.UNSPECIFIED:
          navigate(Routes.VOYAGER_TAX_RESIDENCY_INTRO);
          break;
      }
    }
  }, [
    canReadSaver,
    canReadSuper,
    continueToSuperCrossSell,
    isUserWithNoProduct,
    navigateToSuperCrossSell,
    resp.loading,
    taxResidenceStatus,
  ]);

  return resp.loading ? null : <>{children}</>;
});

VoyagerRoutes.displayName = 'VoyagerRoutes';

const SuperRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> =
  memo(({ children }) => {
    return <>{children}</>;
  });

SuperRoutes.displayName = 'SuperRoutes';

const NovaRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = memo(
  ({ children }) => {
    return <>{children}</>;
  },
);

NovaRoutes.displayName = 'NovaRoutes';

const VoyagerInvestmentPages: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = memo(() => (
  <Router>
    <VoyagerInvestOptions path="/" />
    <VoyagerDeposit path="one-off" />
    <VoyagerDeposit path="one-off/:productId" />
    <VoyagerDepositConfirm path="one-off/confirm/:productId" />
    <VoyagerDepositSuccess path="one-off/success/" />
    <VoyagerDepositSuccess path="one-off/success/:productId" />
    <VoyagerInvestmentPlanPortfolios path="plan/portfolios" />
    <VoyagerInvestmentPlan path="plan" />
    <VoyagerInvestmentPlan path="plan/:productId" />
    <VoyagerInvestmentPlanSuccess path="plan/success" />
    <VoyagerInvestmentPlanSuccess path="plan/success/:productId" />
    <Boosts path="boosts/*" />
    <PageNotFound default={true} />
  </Router>
));

VoyagerInvestmentPages.displayName = 'VoyagerInvestmentPages';
