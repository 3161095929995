import { navigate } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { TransactionStatus } from '@spaceship-fspl/graphql/src/__generated__/globalTypes';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  DATE_FORMAT_TRANSACTIONS_SHORT,
  enumToString,
  formatCurrency,
  formatDate,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { SchedulerFrequency } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { ConfettiShower } from 'components/confetti/shower';
import { CenterPageContainer } from 'components/layouts/page';
import { TransactionStatusIndicator } from 'components/transactions/status-indicator';
import { isValid, subBusinessDays } from 'date-fns';
import { Routes } from 'pages/routes';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

type Variant = 'invest' | 'withdraw';

export interface VoyagerTransactionSuccessProps {
  variant: Variant;
  productId: string;
  amount: string;
  etaDate?: Date;
  frequency?: Exclude<SchedulerFrequency.Enum, SchedulerFrequency.Enum.UNKNOWN>;
}

export const VoyagerTransactionSuccess: React.FC<
  React.PropsWithChildren<VoyagerTransactionSuccessProps>
> = ({
  variant,
  productId,
  amount,
  etaDate,
  frequency = SchedulerFrequency.Enum.ONE_TIME,
}) => {
  const formattedLongEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)
    : undefined;

  const formattedShortEtaDate = etaDate
    ? formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_SHORT)
    : undefined;

  const isWithdrawing = variant === 'withdraw';

  const isMakingOneOffDeposit =
    variant === 'invest' && frequency === SchedulerFrequency.Enum.ONE_TIME;

  const isInvestmentPlan =
    variant === 'invest' && frequency !== SchedulerFrequency.Enum.ONE_TIME;

  return (
    <CenterPageContainer>
      <Box paddingY="xl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box height={BOX_HEIGHT} marginBottom="xxl" position="relative">
                {variant === 'invest' && (
                  <StyledCardConfettiContainer>
                    <ConfettiShower delay={500} />
                  </StyledCardConfettiContainer>
                )}

                <StyledSummaryBox variant={variant}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text variant={3} isBold={true}>
                      {isWithdrawing ? 'Withdrawal' : 'Investment'}
                      {isInvestmentPlan &&
                        ` plan (${enumToString(
                          SchedulerFrequency.Enum,
                          frequency,
                        ).toLowerCase()})`}
                    </Text>

                    <Text variant={1} isBold={true}>
                      {formatCurrency(amount)}
                    </Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Inline spaceX="xxxs" alignY="center">
                      <TransactionStatusIndicator
                        status={TransactionStatus.PENDING}
                      />
                      <Text variant={4} isBold={true} color="neutral.080">
                        Submitted
                      </Text>
                    </Inline>

                    {formattedShortEtaDate && (
                      <Text
                        variant={4}
                        isBold={true}
                        isItalic={true}
                        color="neutral.080"
                      >
                        ETA {formattedShortEtaDate}
                      </Text>
                    )}
                  </Box>
                </StyledSummaryBox>

                <StyledBackgroundBox />
              </Box>

              <Stack spaceY="sm" alignX="center">
                <Heading variant={3} align="center">
                  {isWithdrawing && 'Your withdrawal has been submitted'}
                  {isMakingOneOffDeposit && 'Your investment is on its way!'}
                  {isInvestmentPlan && 'Your investment plan is set!'}
                </Heading>

                {formattedLongEtaDate && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    maxWidth={{ md: 420 }}
                  >
                    <Text variant={1} align="center">
                      {isWithdrawing &&
                        'The ETA of your funds in your bank account is '}
                      {(isMakingOneOffDeposit || isInvestmentPlan) &&
                        'The ETA of your units is '}
                      <strong>{formattedLongEtaDate}</strong>.
                    </Text>
                  </Box>
                )}
              </Stack>

              <Box marginTop="xl">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    navigate(
                      isMakingOneOffDeposit
                        ? `${Routes.VOYAGER_DASHBOARD}/${productId}`
                        : `${Routes.VOYAGER_TRANSACTIONS}/${productId}`,
                    );
                  }}
                  trackingProperties={{
                    name: 'voyager_transaction_success_button',
                  }}
                >
                  {isMakingOneOffDeposit
                    ? 'View my dashboard'
                    : 'View my transactions'}
                </Button>
              </Box>

              {etaDate &&
                isValid(etaDate) &&
                (isMakingOneOffDeposit || isInvestmentPlan) && (
                  <Box
                    display="flex"
                    marginTop="xl"
                    padding="sm"
                    borderRadius="sm"
                    backgroundColor="neutral.050"
                    maxWidth={420}
                  >
                    <Box marginRight="xs">
                      <FeatherInfoIcon color="neutral.080" />
                    </Box>
                    <Text variant={4} isBold={true}>
                      Make sure you have sufficient funds in your bank account
                      before 8pm (Sydney time){' '}
                      {formatDate(
                        subBusinessDays(etaDate, 1),
                        DATE_FORMAT_TRANSACTIONS_LONG,
                      )}{' '}
                      or your investment may fail.
                    </Text>
                  </Box>
                )}
            </Box>
          </Columns.Column>
        </Columns>
      </Box>
    </CenterPageContainer>
  );
};

const StyledCardConfettiContainer = styled.div`
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  z-index: 2;
`;

const BOX_HEIGHT = 98;

const boxAnimationStartState = `
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
`;

const StyledBox = styled(Box).attrs({
  borderRadius: 'sm',
  paddingX: { xs: 'sm', md: 'md' },
  paddingY: 'md',
  height: BOX_HEIGHT,
  width: { xs: 300, md: 340 },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})`
  ${boxAnimationStartState}
  position: absolute;
  top: 50%;
  left: 50%;
`;

const summaryBoxAnimation = keyframes`
  0% {
    ${boxAnimationStartState}
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(-1deg);
  }
`;

const bigMoneyBagAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-5deg);
    left: calc(50% - 24px);
    top: -70px;
  }
  50% {
    opacity: 1;
    transform: rotate(-1deg);
    left: calc(50% - 20px);
    top: -28px;
  }
  75% {
    opacity: 1;
    transform: rotate(7.5deg);
    left: calc(50% - 18px);
    top: -32px;
  }
  100% {
    opacity: 1;
    transform: rotate(13.31deg);
    left: calc(50% - 16px);
    top: -28px;
  }
`;

const smallMoneyBagAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate(2deg);
    left: calc(50% - 16px);
    top: -70px;
  }
  50% {
    opacity: 1;
    transform: rotate(1deg);
    left: calc(50% - 18px);
    top: 0px;
  }
  75% {
    opacity: 1;
    transform: rotate(-9deg);
    left: calc(50% - 20px);
    top: -4px;
  }
  100% {
    opacity: 1;
    transform: rotate(-15deg);
    left: calc(50% - 24px);
    top: 0px;
  }
`;

const StyledSummaryBox = styled(StyledBox).attrs({
  backgroundColor: 'neutral.000',
  boxShadow: 'lg',
})<{ variant: Variant }>`
  animation: ${summaryBoxAnimation} 0.3s ease-in 0.2s forwards;
  z-index: 1;

  ${({ variant }) =>
    variant === 'withdraw'
      ? css`
          ::before,
          ::after {
            content: '💰';
            display: block;
            position: absolute;
            opacity: 0;
          }

          ::before {
            animation: ${bigMoneyBagAnimation} 0.45s ease-in 0.4s forwards;
            font-size: 48px;
          }

          ::after {
            animation: ${smallMoneyBagAnimation} 0.45s ease-in 0.45s forwards;
            font-size: 28px;
          }
        `
      : ''}
`;

const backgroundBoxAnimation = keyframes`
  0% {
    ${boxAnimationStartState}
  }
  100% {
    transform: translateX(calc(-50% + 8px)) translateY(-50%) rotate(4deg);
  }
`;

const StyledBackgroundBox = styled(StyledBox).attrs({
  backgroundColor: 'neutral.050',
})`
  animation: ${backgroundBoxAnimation} 0.3s ease-in 0.2s forwards;
  z-index: 0;
`;
