import {
  Box,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { useGetEstimatedAnonymousApplicationExecutionDate } from '@spaceship-fspl/data';
import {
  useEstimatedApplicationExecutionDate,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerDeposit_contact_account_saverProductInstances_transactionLimits as SaverTransactionLimits } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDeposit';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  ExternalRoutes,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
  useDebounceValue,
} from '@spaceship-fspl/helpers';
import { FeatherClockIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  InvestmentType,
  useInvestmentValidation,
} from '@spaceship-fspl/voyager';
import { Button } from 'components/button';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormSkipButton,
  PageHeading,
} from 'components/layouts/page';
import { InvestInfoAccordion } from 'components/transactions/invest-info-accordion';
import { useIntercom } from 'contexts/intercom';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { cleanNumber } from 'helpers/format';
import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { ControllerInput } from './controller-input';
import { Dropdown, Option } from './dropdown';
import { RadioButton } from './radio-button';

const PRESET_AMOUNTS = ['25', '50', '100'];

export interface OneOffDepositOnCompleteData {
  productId: string;
  audAmount: string;
  etaDate?: string | null;
}

export interface VoyagerOneOffDepositFormProps {
  variant: 'onboarding' | 'loggedin';
  onComplete?: (data: OneOffDepositOnCompleteData) => void;
  isSubmitting?: boolean;
  activeBankAccountNumber?: string;
  onSkip?: () => void;
  productOptions?: Array<
    Option & { transactionLimits: SaverTransactionLimits }
  >;
  defaultProductId?: string;
}

export const VoyagerOneOffDepositForm: React.FC<
  React.PropsWithChildren<VoyagerOneOffDepositFormProps>
> = (props) => {
  const validate = useInvestmentValidation(InvestmentType.ONE_OFF);
  const flagsData = useSaverPortfolioFlags();

  const { control, register, setValue, watch, handleSubmit } = useForm<{
    amount: string;
    presetAmount: string;
    productId: string;
  }>({
    defaultValues: {
      amount: '',
      presetAmount: '',
      productId: '',
    },
  });

  useEffect(() => {
    if (
      props.defaultProductId &&
      !!props.productOptions?.find(
        (product) => product.value === props.defaultProductId,
      )
    ) {
      setValue('productId', props.defaultProductId);
    } else if (props.productOptions?.[0]) {
      setValue('productId', props.productOptions[0]?.value?.toString() ?? '');
    }
  }, [props.defaultProductId, props.productOptions, setValue]);

  const productId = watch('productId');
  const amount = watch('amount');
  const presetAmount = watch('presetAmount');

  useEffect(() => {
    if (amount) {
      setValue('presetAmount', '');
    }
    if (presetAmount) {
      setValue('amount', '', { shouldValidate: true });
    }
  }, [amount, presetAmount, setValue]);

  const chosenAmount = cleanNumber(amount || presetAmount || '');
  const [debouncedAmountValue] = useDebounceValue(chosenAmount, 300);

  const intercom = useIntercom();

  const productTransactionLimits = useMemo(
    () =>
      props.productOptions?.find((p) => p.value === productId)
        ?.transactionLimits,
    [props.productOptions, productId],
  );

  // Expect that props.productOptions may be undefined during onboarding since the product instance has not yet been
  // created - default to true when productTransactionLimits is undefined.
  const directDebitBuyAvailable =
    productTransactionLimits?.directDebitBuyAvailable ?? true;

  const sydneyDateRef = useRef(sydneyDate()).current;
  const estimatedExecutionDateResp = useEstimatedApplicationExecutionDate({
    variables: {
      productId: productId || '',
      audAmount: debouncedAmountValue || '',
      applicationTime: sydneyDateRef.toISOString(),
    },
    skip: props.variant === 'onboarding' || !productId || !debouncedAmountValue,
  });

  const anonEstimatedExecutionDateResp =
    useGetEstimatedAnonymousApplicationExecutionDate(debouncedAmountValue);

  const estimatedExecutionDate =
    props.variant === 'onboarding'
      ? anonEstimatedExecutionDateResp.data
          ?.estimated_application_execution_date
      : estimatedExecutionDateResp.data?.contact.account?.saverProductInstance
          ?.estimatedApplicationExecutionDate;

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createPaymentScheduleAvailable =
    saverPortfolioFlags?.createPaymentScheduleAvailable ?? true;

  if (!createPaymentScheduleAvailable) {
    return (
      <Error
        title={
          props.variant === 'onboarding'
            ? 'How much would you like to invest today?'
            : 'Make an investment'
        }
        subtitle={
          saverPortfolioFlags?.createPaymentScheduleNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            window.history.back();
          },
          trackingProperties: {
            name: 'payment_schedule_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <Stack spaceY="xxl">
      <form
        onSubmit={handleSubmit((data): void => {
          props.onComplete?.({
            productId: data.productId,
            audAmount: cleanNumber(data.amount || data.presetAmount),
            etaDate: estimatedExecutionDate,
          });
        })}
      >
        <Columns alignX="center">
          <Columns.Column
            width={{
              xs: 1,
              lg: props.variant === 'onboarding' ? 4 / 12 : 6 / 12,
              xl: 4 / 12,
            }}
          >
            <Stack spaceY="md">
              <Stack spaceY="lg">
                <PageHeading
                  title={
                    props.variant === 'onboarding'
                      ? 'How much would you like to invest today?'
                      : 'Make an investment'
                  }
                />

                {props.variant === 'loggedin' &&
                  props.productOptions &&
                  props.productOptions.length > 1 && (
                    <Dropdown
                      placeholder="Select portfolio"
                      options={props.productOptions ?? []}
                      {...register('productId', {
                        required: 'Portfolio is required',
                      })}
                    />
                  )}
              </Stack>

              {!directDebitBuyAvailable ? (
                <Stack spaceY="md" alignX="center">
                  <Box
                    backgroundColor="neutral.000"
                    borderRadius="xs"
                    padding="sm"
                    display="flex"
                  >
                    <FeatherInfoIcon color="indigo.070" />
                    <Box flex={1} marginLeft="xs">
                      <Text variant={3}>
                        {
                          productTransactionLimits?.directDebitBuyNotAvailableMessage
                        }
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'one_off_deposit_contact_support',
                    }}
                    onClick={() => intercom.pop()}
                  >
                    Contact support
                  </Button>
                </Stack>
              ) : (
                <>
                  <Stack spaceY="lg">
                    <Stack spaceY="xs">
                      <Text variant={4} color="neutral.080" isBold={true}>
                        Amount
                      </Text>
                      <Columns spaceX="sm">
                        {PRESET_AMOUNTS.map((option) => {
                          return (
                            <Columns.Column width="max" key={option}>
                              <RadioButton
                                value={option}
                                {...register('presetAmount')}
                              >
                                <Text variant={2} isBold={true} align="center">
                                  ${option}
                                </Text>
                              </RadioButton>
                            </Columns.Column>
                          );
                        })}
                      </Columns>

                      <ControllerInput
                        name="amount"
                        control={control}
                        width="100%"
                        placeholder="Other amount"
                        type="text"
                        format="currency"
                        rules={{
                          validate: (value: string) =>
                            !presetAmount ? validate(value) : true,
                        }}
                      />
                    </Stack>
                  </Stack>

                  {estimatedExecutionDate && (
                    <Box
                      backgroundColor="neutral.030"
                      borderRadius="xs"
                      padding="md"
                    >
                      <Stack spaceY="xxs" alignX="center">
                        <Box color="indigo.070">
                          <FeatherClockIcon size="md" />
                        </Box>
                        <Text variant={2} align="center">
                          The ETA of your next units is{' '}
                          <Text variant={2} isBold={true} component="span">
                            {formatDate(
                              estimatedExecutionDate,
                              DATE_FORMAT_TRANSACTIONS_LONG,
                            )}
                          </Text>
                        </Text>
                      </Stack>
                    </Box>
                  )}

                  <Stack spaceY="sm">
                    {props.activeBankAccountNumber && (
                      <Text variant={3} color="neutral.080">
                        Your investment will be transferred from your bank
                        account ending in{' '}
                        {maskBankAccountNumber(props.activeBankAccountNumber)}.
                      </Text>
                    )}
                    <Text variant={3} color="neutral.080">
                      By clicking ‘
                      {props.variant === 'onboarding'
                        ? 'Continue'
                        : 'Add investment'}
                      ’, you acknowledge this investment will be processed in
                      accordance with our{' '}
                      <TextLink
                        color="indigo.070"
                        target="_blank"
                        rel="noreferrer"
                        href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                      >
                        Direct Debit Request
                      </TextLink>{' '}
                      and{' '}
                      <TextLink
                        color="indigo.070"
                        target="_blank"
                        rel="noreferrer"
                        href={ExternalRoutes.ZEPTO_DEBIT_AGREEMENT}
                      >
                        Direct Debit Request Service Agreement
                      </TextLink>
                      .
                    </Text>
                  </Stack>
                </>
              )}
            </Stack>

            {directDebitBuyAvailable && (
              <PageFormButtonContainer>
                <PageFormContinueButton
                  trackingProperties={{ name: 'one_off_deposit_continue' }}
                  isLoading={props.isSubmitting}
                >
                  {props.variant === 'onboarding'
                    ? 'Continue'
                    : 'Add investment'}
                </PageFormContinueButton>

                {props.variant === 'onboarding' && (
                  <PageFormSkipButton
                    trackingProperties={{ name: 'one_off_deposit_skip' }}
                    onClick={props.onSkip}
                  />
                )}
              </PageFormButtonContainer>
            )}
          </Columns.Column>
        </Columns>
      </form>

      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 5 / 6 }}>
          <InvestInfoAccordion />
        </Columns.Column>
      </Columns>
    </Stack>
  );
};
