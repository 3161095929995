import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Columns,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  SaverTransactionLimits_SaverProductInstanceFragment,
  ScheduleFrequency,
  useCreatePaymentSchedule,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerDeposit } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDeposit';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { formatCurrency } from '@spaceship-fspl/helpers';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { PageContainer } from 'components/layouts/page';
import {
  OneOffDepositOnCompleteData,
  VoyagerOneOffDepositForm,
} from 'components/voyager-one-off-deposit-form';
import { useIntercom } from 'contexts/intercom';
import { useNotifications } from 'contexts/notifications';
import {
  GENERIC_ERROR_MESSAGE,
  VOYAGER_DEPOSIT_LIMIT_EXCEEDED,
} from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import React, { useMemo } from 'react';

import { Routes } from './routes';

interface VoyagerDepositProps {
  productId?: string;
}

export const VoyagerDeposit: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerDepositProps>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: VoyagerDepositProps = useParams();

  const intercom = useIntercom();
  const notifications = useNotifications();
  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const [createPaymentSchedule, { loading: isSubmitting }] =
    useCreatePaymentSchedule();

  const resp = useQuery<WebAppVoyagerDeposit>(gql`
    query WebAppVoyagerDeposit {
      contact {
        id
        account {
          id
          activeBankAccount {
            id
            accountNumber
          }
          saverProductInstances {
            id
            portfolio
            investments {
              id
              summary {
                id
                audBalance
              }
            }
            portfolioInformation {
              id
              title
            }
            ...SaverTransactionLimits_SaverProductInstanceFragment
          }
        }
      }
    }
    ${SaverTransactionLimits_SaverProductInstanceFragment}
  `);

  const productOptions = useMemo(
    () =>
      resp.data?.contact?.account?.saverProductInstances?.map((p) => {
        const title = p.portfolioInformation?.title;
        const balance = formatCurrency(p.investments?.summary.audBalance);
        return {
          label: `${title} (${balance})`,
          value: p.id,
          transactionLimits: p.transactionLimits,
        };
      }),
    [resp],
  );

  const handleSubmit = async ({
    productId,
    audAmount,
    etaDate,
  }: OneOffDepositOnCompleteData): Promise<void> => {
    try {
      await createPaymentSchedule({
        variables: {
          input: {
            productId,
            audAmount,
            frequency: ScheduleFrequency.ONE_TIME,
          },
        },
      });
      navigate(`${Routes.VOYAGER_DEPOSIT_SUCCESS}/${productId}`, {
        state: {
          productId,
          audAmount,
          etaDate,
        },
      });
    } catch (error) {
      addRumError({ error });
      notifications.popToast({
        level: 'error',
        message: (error as Error | undefined)?.message.includes(
          'max number of daily deposits allowed exceeded',
        )
          ? VOYAGER_DEPOSIT_LIMIT_EXCEEDED
          : GENERIC_ERROR_MESSAGE,
      });
    }
  };

  return (
    <PageContainer>
      {resp.loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : isVerifiedUser ? (
        <VoyagerOneOffDepositForm
          variant="loggedin"
          onComplete={handleSubmit}
          isSubmitting={isSubmitting}
          activeBankAccountNumber={
            resp?.data?.contact?.account?.activeBankAccount?.accountNumber || ''
          }
          productOptions={productOptions}
          defaultProductId={productId}
        />
      ) : (
        <form>
          <Columns alignX="center">
            <Columns.Column
              width={{
                xs: 1,
                lg: 6 / 12,
                xl: 4 / 12,
              }}
            >
              <Stack spaceY="lg" alignX="center">
                <Heading variant={3} component="h1" align="center">
                  Make an investment
                </Heading>

                <Stack spaceY="md">
                  <Text variant={2}>
                    Before you can make a one off investment, we need to verify
                    your identity.
                  </Text>
                </Stack>
                <Button
                  variant="secondary"
                  size="lg"
                  trackingProperties={{
                    name: 'one_off_deposit_contact_support',
                  }}
                  onClick={() => intercom.pop()}
                >
                  Contact support
                </Button>
              </Stack>
            </Columns.Column>
          </Columns>
        </form>
      )}
    </PageContainer>
  );
};
