import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  InfoPanel,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { WebAppMoneyWithdrawalPage } from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyWithdrawalPage';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import {
  cleanNumber,
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatCurrency,
  formatDate,
  maskBankAccountNumber,
  stringToDate,
  toSydneyStartOfDay,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { Error, InlineContactSupportMessage } from 'components/layouts/error';
import {
  CenterPageContainer,
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
} from 'components/layouts/page';
import { PendingVerification } from 'components/layouts/pending-verification';
import { LinkButton } from 'components/link-button';
import { useIntercom } from 'contexts/intercom';
import { addBusinessDays } from 'date-fns';
import { TestId } from 'helpers/test-ids';
import { commonValidationRules } from 'helpers/validation';
import React, { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FeatureFlagKeys, useFeatureFlag } from '../../helpers/dynamic-config';
import { Routes } from '../routes';

const WITHDRAWAL_MIN_AUD_AMOUNT_FALLBACK = 5;
const WITHDRAWAL_NOT_AVAILABLE_MESSAGE_FALLBACK =
  'Withdrawals are not available at this time. Please contact support.';

export const MoneyWithdraw: React.FC<RouteComponentProps> = () => {
  const intercom = useIntercom();
  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );
  const navigate = useNavigate();

  const { control, formState, watch, handleSubmit, setValue } = useForm<{
    applicationTime: Date;
    audAmount: string;
  }>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      applicationTime: toSydneyStartOfDay(new Date()),
    },
  });

  const withdrawalAmount = watch('audAmount');
  const applicationTime = watch('applicationTime');

  const submit = handleSubmit(async (input: { audAmount: string }) => {
    const cleaned = cleanNumber(input.audAmount);
    navigate(Routes.MONEY_WITHDRAW_CONFIRM, {
      state: {
        audAmount: cleaned,
        etaDate: formattedEstimatedExecutionDate,
      },
    });
  });

  const resp = useQuery<WebAppMoneyWithdrawalPage>(
    gql`
      query WebAppMoneyWithdrawalPage(
        $isMoneyDayOneEnabled: Boolean! = false
        $applicationTime: String!
      ) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            ... on Account @include(if: $isMoneyDayOneEnabled) {
              moneyAvailableAudBalance
              moneyInstance {
                id
                withdrawalEstimateTransactionTime: estimateTransactionTime(
                  estimateType: MONEY_OUT
                  applicationTime: $applicationTime
                )
                transactionLimits {
                  withdrawalAvailable
                  withdrawalMinAudAmount
                  withdrawalNotAvailableMessage
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        isMoneyDayOneEnabled,
        applicationTime: applicationTime.toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isLoading = resp.loading;
  const error = resp.error;

  const account = resp.data?.contact?.account;
  const transactionLimits = account?.moneyInstance?.transactionLimits;

  const withdrawalMinAudAmount = Number(
    transactionLimits?.withdrawalMinAudAmount ??
      WITHDRAWAL_MIN_AUD_AMOUNT_FALLBACK,
  );

  const moneyAvailableAudBalance = Number(
    account?.moneyAvailableAudBalance ?? 0,
  );
  const isBalanceZeroOrNegative = moneyAvailableAudBalance <= 0;
  const bankAccountNumber =
    resp.data?.contact?.account?.activeBankAccount?.accountNumber;

  const withdrawalInputDisabled =
    moneyAvailableAudBalance < withdrawalMinAudAmount &&
    moneyAvailableAudBalance > 0;
  const withdrawalAvailable = transactionLimits?.withdrawalAvailable;
  const withdrawalNotAvailableMessage =
    transactionLimits?.withdrawalNotAvailableMessage ??
    WITHDRAWAL_NOT_AVAILABLE_MESSAGE_FALLBACK;

  const estimatedExecutionDate = account?.moneyInstance
    ?.withdrawalEstimateTransactionTime
    ? stringToDate(account.moneyInstance.withdrawalEstimateTransactionTime)
    : addBusinessDays(applicationTime, 2);

  const formattedEstimatedExecutionDate = formatDate(
    estimatedExecutionDate,
    DATE_FORMAT_TRANSACTIONS_LONG,
  );

  const novaVerified = useIsStatusVerified(GreenIdRuleSet.Enum.NOVA);
  const voyagerVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const isVerifiedUser = novaVerified || voyagerVerified;

  useEffect(() => {
    if (
      moneyAvailableAudBalance > 0 &&
      moneyAvailableAudBalance < withdrawalMinAudAmount
    ) {
      setValue('audAmount', formatCurrency(moneyAvailableAudBalance), {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [moneyAvailableAudBalance, setValue, withdrawalMinAudAmount]);

  const renderAfterAmount = ({
    availableMoney,
    withdrawalAmount,
    withdrawalMinAmount,
    formIsTouched,
    formIsDirty,
  }: {
    availableMoney: number;
    withdrawalMinAmount: number;
    withdrawalAmount?: string;
    formIsTouched?: boolean;
    formIsValid?: boolean;
    formIsDirty?: boolean;
  }): ReactElement => {
    const withdrawal = Number(cleanNumber(withdrawalAmount ?? ''));

    if (availableMoney < withdrawalMinAmount && availableMoney > 0) {
      return (
        <Text
          data-testid={'money_withdrawal_page_after_amount'}
          variant={2}
          color={'pink.030'}
          isBold
        >
          {formatCurrency('0')}
        </Text>
      );
    }

    // if form has not been dirtied/touched
    if (!formIsDirty && !formIsTouched && availableMoney) {
      return (
        <Text
          data-testid={'money_withdrawal_page_after_amount'}
          color="neutral.080"
          isBold
          variant={2}
        >
          {formatCurrency(moneyAvailableAudBalance)}
        </Text>
      );
    }

    // if valid value is entered
    if (availableMoney && withdrawal >= withdrawalMinAmount) {
      const available = Number(availableMoney);
      const amount = available - withdrawal;
      if (amount >= 0) {
        return (
          <Text
            data-testid={'money_withdrawal_page_after_amount'}
            variant={2}
            color={'pink.030'}
            isBold
          >
            {formatCurrency(amount)}
          </Text>
        );
      }
    }
    return (
      <Text
        data-testid={'money_withdrawal_page_after_amount'}
        variant={2}
        isBold
      >
        {formatCurrency('')}
      </Text>
    );
  };

  if (isLoading) {
    return (
      <CenterPageContainer>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      </CenterPageContainer>
    );
  }

  if (error && !resp.data && !isLoading) {
    return (
      <Error
        title="We've run into a problem."
        subtitle={<InlineContactSupportMessage />}
        buttonText="Try again"
        iconColor="indigo.070"
        onContinue={{
          onClick: () => resp.refetch(),
          trackingProperties: {
            name: 'money_withdraw_error_page:contact_support',
          },
        }}
        secondaryButton={
          <LinkButton
            size="sm"
            trackingProperties={{ name: 'money_withdraw_contact_support' }}
            onClick={() => navigate(Routes.MONEY)}
          >
            Go back
          </LinkButton>
        }
      />
    );
  }

  if (!isVerifiedUser) {
    return (
      <CenterPageContainer>
        <PendingVerification
          variant="withdraw-money"
          onBack={{
            trackingProperties: {
              name: 'money_withdrawal_verify_go_back',
            },
            onClick: () => navigate(Routes.MONEY),
          }}
        />
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer>
      <Stack spaceY="xxl">
        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
            <Stack spaceY="md">
              <Heading component={'h2'} variant={3} align="center">
                How much money would you like to withdraw?
              </Heading>
              {withdrawalAvailable ? (
                <Box marginTop="md">
                  {isBalanceZeroOrNegative ? (
                    <InfoPanel
                      testId={
                        TestId.MONEY_WITHDRAW_PAGE_NO_AVAILABLE_FUNDS_INFO_PANEL
                      }
                      backgroundColor={'indigo.010'}
                      boxShadow={'none'}
                      color={'black.100'}
                      hidePanelFill
                      icon={FeatherInfoIcon}
                      iconAlignment="center"
                      iconSize={'sm'}
                      leftBorderColor={'indigo.010'}
                      marginBottom="md"
                      textColor={'black.100'}
                      textVariant={3}
                    >
                      No available funds to withdraw.
                    </InfoPanel>
                  ) : null}

                  {withdrawalInputDisabled ? (
                    <InfoPanel
                      testId={TestId.MONEY_WITHDRAW_PAGE_MIN_AMOUNT_INFO_PANEL}
                      backgroundColor={'indigo.010'}
                      boxShadow={'none'}
                      color={'black.100'}
                      hidePanelFill
                      icon={FeatherInfoIcon}
                      iconAlignment="center"
                      iconSize={'sm'}
                      leftBorderColor={'indigo.010'}
                      marginBottom="md"
                      textColor={'black.100'}
                      textVariant={3}
                    >
                      For balances less than{' '}
                      {formatCurrency(withdrawalMinAudAmount)}, the only option
                      is to withdraw your entire balance.
                    </InfoPanel>
                  ) : null}

                  <form onSubmit={submit}>
                    <Stack spaceY="sm">
                      <ControllerInput
                        name="audAmount"
                        label="Amount"
                        control={control}
                        type="text"
                        format="currency"
                        placeholder="Amount"
                        disabled={withdrawalInputDisabled}
                        rules={{
                          required: 'Withdrawal amount is required',
                          validate: {
                            atMostBalance: (value?: string) =>
                              value && moneyAvailableAudBalance
                                ? commonValidationRules.atMost.validate(
                                    moneyAvailableAudBalance,
                                    value,
                                  )
                                : false,
                            atLeast: (value?: string): boolean | string => {
                              if (value && Number(cleanNumber(value)) < 0) {
                                return 'Amount is invalid';
                              }

                              // form control is disabled if available balance is less than $5,
                              // and user may withdraw the full amount
                              if (
                                Number(moneyAvailableAudBalance) <
                                withdrawalMinAudAmount
                              ) {
                                return true;
                              }

                              return value
                                ? commonValidationRules.atLeast.validate(
                                    withdrawalMinAudAmount ?? '5.00',
                                    value,
                                  )
                                : false;
                            },
                          },
                        }}
                      />
                      <Divider color="neutral.030" />
                      <Columns>
                        <Columns.Column width={1 / 2}>
                          <Text
                            color={'neutral.080'}
                            component={'h3'}
                            isBold
                            variant={4}
                          >
                            Now
                          </Text>
                          <Text
                            data-testid={TestId.MONEY_WITHDRAW_PAGE_NOW_AMOUNT}
                            variant={2}
                            isBold
                          >
                            {formatCurrency(moneyAvailableAudBalance)}
                          </Text>
                        </Columns.Column>
                        <Columns.Column width={1 / 2}>
                          <Text
                            color={'neutral.080'}
                            component={'h3'}
                            isBold
                            variant={4}
                          >
                            After
                          </Text>
                          {renderAfterAmount({
                            availableMoney: moneyAvailableAudBalance,
                            withdrawalAmount: withdrawalAmount,
                            withdrawalMinAmount: withdrawalMinAudAmount,
                            formIsDirty: formState.isDirty,
                            formIsTouched: formState.touchedFields.audAmount,
                          })}
                        </Columns.Column>
                      </Columns>
                      <Divider color="neutral.030" />
                      <Stack spaceY="xxs">
                        <Text
                          color={'neutral.080'}
                          component={'h3'}
                          isBold
                          variant={4}
                        >
                          Destination
                        </Text>
                        <Text isBold variant={2}>
                          Linked bank account
                        </Text>
                        <Text variant={3} color={'neutral.080'}>
                          The proceeds from your withdrawal will be transferred
                          to your account ending in{' '}
                          {maskBankAccountNumber(bankAccountNumber)}.
                        </Text>
                      </Stack>
                    </Stack>

                    <PageFormButtonContainer>
                      <PageFormContinueButton
                        trackingProperties={{ name: 'money_withdraw_continue' }}
                      >
                        Continue
                      </PageFormContinueButton>

                      <Box marginTop="sm">
                        <TextLink
                          data-testid={TestId.MONEY_WITHDRAW_PAGE_CANCEL_BUTTON}
                          hideUnderline
                          color="indigo.070"
                          onClick={() => {
                            navigate(Routes.MONEY);
                          }}
                        >
                          Cancel
                        </TextLink>
                      </Box>
                    </PageFormButtonContainer>
                  </form>
                </Box>
              ) : (
                <Stack spaceY="md" alignX="center">
                  <Box
                    backgroundColor="neutral.000"
                    borderRadius="xs"
                    padding="sm"
                    display="flex"
                  >
                    <FeatherInfoIcon color="indigo.070" />
                    <Box flex={1} marginLeft="xs">
                      <Text variant={3}>{withdrawalNotAvailableMessage}</Text>
                    </Box>
                  </Box>

                  <Button
                    variant="primary"
                    size="lg"
                    trackingProperties={{
                      name: 'money_withdraw_not_available_go_back',
                    }}
                    onClick={() => navigate(-1)}
                    data-testid={TestId.MONEY_WITHDRAW_PAGE_GO_BACK_BUTTON}
                  >
                    Go back
                  </Button>
                  <Button
                    variant="secondary"
                    size="lg"
                    trackingProperties={{
                      name: 'money_withdraw_not_available_contact_support',
                    }}
                    onClick={() => intercom.pop()}
                    data-testid={
                      TestId.MONEY_WITHDRAW_PAGE_CONTACT_SUPPORT_BUTTON
                    }
                  >
                    Contact support
                  </Button>
                </Stack>
              )}
            </Stack>
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};
