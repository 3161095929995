export const STATES = [
  { value: '', label: 'State' },
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'WA', label: 'WA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'ACT', label: 'ACT' },
  { value: 'NT', label: 'NT' },
];

export const STREET_TYPES = [
  {
    label: 'Street',
    value: 'Street',
  },
  {
    label: 'Road',
    value: 'Road',
  },
  {
    label: 'Avenue',
    value: 'Avenue',
  },
  {
    label: 'Drive',
    value: 'Drive',
  },
  {
    label: 'Court',
    value: 'Court',
  },
  {
    label: 'Access',
    value: 'Access',
  },
  {
    label: 'Alley',
    value: 'Alley',
  },
  {
    label: 'Alleyway',
    value: 'Alleyway',
  },
  {
    label: 'Amble',
    value: 'Amble',
  },
  {
    label: 'Anchorage',
    value: 'Anchorage',
  },
  {
    label: 'Approach',
    value: 'Approach',
  },
  {
    label: 'Arcade',
    value: 'Arcade',
  },
  {
    label: 'Artery',
    value: 'Artery',
  },
  {
    label: 'Basin',
    value: 'Basin',
  },
  {
    label: 'Beach',
    value: 'Beach',
  },
  {
    label: 'Bend',
    value: 'Bend',
  },
  {
    label: 'Block',
    value: 'Block',
  },
  {
    label: 'Boulevard',
    value: 'Boulevard',
  },
  {
    label: 'Brace',
    value: 'Brace',
  },
  {
    label: 'Brae',
    value: 'Brae',
  },
  {
    label: 'Break',
    value: 'Break',
  },
  {
    label: 'Bridge',
    value: 'Bridge',
  },
  {
    label: 'Broadway',
    value: 'Broadway',
  },
  {
    label: 'Brow',
    value: 'Brow',
  },
  {
    label: 'Bypass',
    value: 'Bypass',
  },
  {
    label: 'Byway',
    value: 'Byway',
  },
  {
    label: 'Causeway',
    value: 'Causeway',
  },
  {
    label: 'Centre',
    value: 'Centre',
  },
  {
    label: 'Centreway',
    value: 'Centreway',
  },
  {
    label: 'Chase',
    value: 'Chase',
  },
  {
    label: 'Circle',
    value: 'Circle',
  },
  {
    label: 'Circlet',
    value: 'Circlet',
  },
  {
    label: 'Circuit',
    value: 'Circuit',
  },
  {
    label: 'Circus',
    value: 'Circus',
  },
  {
    label: 'Close',
    value: 'Close',
  },
  {
    label: 'Colonnade',
    value: 'Colonnade',
  },
  {
    label: 'Common',
    value: 'Common',
  },
  {
    label: 'Concourse',
    value: 'Concourse',
  },
  {
    label: 'Copse',
    value: 'Copse',
  },
  {
    label: 'Corner',
    value: 'Corner',
  },
  {
    label: 'Corso',
    value: 'Corso',
  },
  {
    label: 'Courtyard',
    value: 'Courtyard',
  },
  {
    label: 'Cove',
    value: 'Cove',
  },
  {
    label: 'Crescent',
    value: 'Crescent',
  },
  {
    label: 'Crest',
    value: 'Crest',
  },
  {
    label: 'Cross',
    value: 'Cross',
  },
  {
    label: 'Crossing',
    value: 'Crossing',
  },
  {
    label: 'Crossroad',
    value: 'Crossroad',
  },
  {
    label: 'Crossway',
    value: 'Crossway',
  },
  {
    label: 'Cruiseway',
    value: 'Cruiseway',
  },
  {
    label: 'Cul-de-sac',
    value: 'Cul-de-sac',
  },
  {
    label: 'Cutting',
    value: 'Cutting',
  },
  {
    label: 'Dale',
    value: 'Dale',
  },
  {
    label: 'Dell',
    value: 'Dell',
  },
  {
    label: 'Deviation',
    value: 'Deviation',
  },
  {
    label: 'Dip',
    value: 'Dip',
  },
  {
    label: 'Distributor',
    value: 'Distributor',
  },

  {
    label: 'Driveway',
    value: 'Driveway',
  },
  {
    label: 'Edge',
    value: 'Edge',
  },
  {
    label: 'Elbow',
    value: 'Elbow',
  },
  {
    label: 'End',
    value: 'End',
  },
  {
    label: 'Entrance',
    value: 'Entrance',
  },
  {
    label: 'Esplanade',
    value: 'Esplanade',
  },
  {
    label: 'Estate',
    value: 'Estate',
  },
  {
    label: 'Expressway',
    value: 'Expressway',
  },
  {
    label: 'Extension',
    value: 'Extension',
  },
  {
    label: 'Fairway',
    value: 'Fairway',
  },
  {
    label: 'Fire track',
    value: 'Fire track',
  },
  {
    label: 'Firetrail',
    value: 'Firetrail',
  },
  {
    label: 'Flat',
    value: 'Flat',
  },
  {
    label: 'Follow',
    value: 'Follow',
  },
  {
    label: 'Footway',
    value: 'Footway',
  },
  {
    label: 'Foreshore',
    value: 'Foreshore',
  },
  {
    label: 'Formation',
    value: 'Formation',
  },
  {
    label: 'Freeway',
    value: 'Freeway',
  },
  {
    label: 'Front',
    value: 'Front',
  },
  {
    label: 'Frontage',
    value: 'Frontage',
  },
  {
    label: 'Gap',
    value: 'Gap',
  },
  {
    label: 'Garden',
    value: 'Garden',
  },
  {
    label: 'Gate',
    value: 'Gate',
  },
  {
    label: 'Gardens',
    value: 'Gardens',
  },
  {
    label: 'Gates',
    value: 'Gates',
  },
  {
    label: 'Glade',
    value: 'Glade',
  },
  {
    label: 'Glen',
    value: 'Glen',
  },
  {
    label: 'Grange',
    value: 'Grange',
  },
  {
    label: 'Green',
    value: 'Green',
  },
  {
    label: 'Ground',
    value: 'Ground',
  },
  {
    label: 'Grove',
    value: 'Grove',
  },
  {
    label: 'Gully',
    value: 'Gully',
  },
  {
    label: 'Heights',
    value: 'Heights',
  },
  {
    label: 'Highroad',
    value: 'Highroad',
  },
  {
    label: 'Highway',
    value: 'Highway',
  },
  {
    label: 'Hill',
    value: 'Hill',
  },
  {
    label: 'Interchange',
    value: 'Interchange',
  },
  {
    label: 'Intersection',
    value: 'Intersection',
  },
  {
    label: 'Junction',
    value: 'Junction',
  },
  {
    label: 'Key',
    value: 'Key',
  },
  {
    label: 'Landing',
    value: 'Landing',
  },
  {
    label: 'Lane',
    value: 'Lane',
  },
  {
    label: 'Laneway',
    value: 'Laneway',
  },
  {
    label: 'Lees',
    value: 'Lees',
  },
  {
    label: 'Line',
    value: 'Line',
  },
  {
    label: 'Link',
    value: 'Link',
  },
  {
    label: 'Little',
    value: 'Little',
  },
  {
    label: 'Lookout',
    value: 'Lookout',
  },
  {
    label: 'Loop',
    value: 'Loop',
  },
  {
    label: 'Lower',
    value: 'Lower',
  },
  {
    label: 'Mall',
    value: 'Mall',
  },
  {
    label: 'Meander',
    value: 'Meander',
  },
  {
    label: 'Mew',
    value: 'Mew',
  },
  {
    label: 'Mews',
    value: 'Mews',
  },
  {
    label: 'Motorway',
    value: 'Motorway',
  },
  {
    label: 'Mount',
    value: 'Mount',
  },
  {
    label: 'Nook',
    value: 'Nook',
  },
  {
    label: 'Outlook',
    value: 'Outlook',
  },
  {
    label: 'Parade',
    value: 'Parade',
  },
  {
    label: 'Park',
    value: 'Park',
  },
  {
    label: 'Parklands',
    value: 'Parklands',
  },
  {
    label: 'Parkway',
    value: 'Parkway',
  },
  {
    label: 'Part',
    value: 'Part',
  },
  {
    label: 'Pass',
    value: 'Pass',
  },
  {
    label: 'Path',
    value: 'Path',
  },
  {
    label: 'Pathway',
    value: 'Pathway',
  },
  {
    label: 'Piazza',
    value: 'Piazza',
  },
  {
    label: 'Place',
    value: 'Place',
  },
  {
    label: 'Plateau',
    value: 'Plateau',
  },
  {
    label: 'Plaza',
    value: 'Plaza',
  },
  {
    label: 'Pocket',
    value: 'Pocket',
  },
  {
    label: 'Point',
    value: 'Point',
  },
  {
    label: 'Port',
    value: 'Port',
  },
  {
    label: 'Promenade',
    value: 'Promenade',
  },
  {
    label: 'Quad',
    value: 'Quad',
  },
  {
    label: 'Quadrangle',
    value: 'Quadrangle',
  },
  {
    label: 'Quadrant',
    value: 'Quadrant',
  },
  {
    label: 'Quay',
    value: 'Quay',
  },
  {
    label: 'Quays',
    value: 'Quays',
  },
  {
    label: 'Ramble',
    value: 'Ramble',
  },
  {
    label: 'Ramp',
    value: 'Ramp',
  },
  {
    label: 'Range',
    value: 'Range',
  },
  {
    label: 'Reach',
    value: 'Reach',
  },
  {
    label: 'Reserve',
    value: 'Reserve',
  },
  {
    label: 'Rest',
    value: 'Rest',
  },
  {
    label: 'Retreat',
    value: 'Retreat',
  },
  {
    label: 'Ride',
    value: 'Ride',
  },
  {
    label: 'Ridge',
    value: 'Ridge',
  },
  {
    label: 'Ridgeway',
    value: 'Ridgeway',
  },
  {
    label: 'Right of way',
    value: 'Right of way',
  },
  {
    label: 'Ring',
    value: 'Ring',
  },
  {
    label: 'Rise',
    value: 'Rise',
  },
  {
    label: 'River',
    value: 'River',
  },
  {
    label: 'Riverway',
    value: 'Riverway',
  },
  {
    label: 'Riviera',
    value: 'Riviera',
  },

  {
    label: 'Roads',
    value: 'Roads',
  },
  {
    label: 'Roadside',
    value: 'Roadside',
  },
  {
    label: 'Roadway',
    value: 'Roadway',
  },
  {
    label: 'Ronde',
    value: 'Ronde',
  },
  {
    label: 'Rosebowl',
    value: 'Rosebowl',
  },
  {
    label: 'Rotary',
    value: 'Rotary',
  },
  {
    label: 'Round',
    value: 'Round',
  },
  {
    label: 'Route',
    value: 'Route',
  },
  {
    label: 'Row',
    value: 'Row',
  },
  {
    label: 'Rue',
    value: 'Rue',
  },
  {
    label: 'Run',
    value: 'Run',
  },
  {
    label: 'Service way',
    value: 'Service way',
  },
  {
    label: 'Siding',
    value: 'Siding',
  },
  {
    label: 'Slope',
    value: 'Slope',
  },
  {
    label: 'Sound',
    value: 'Sound',
  },
  {
    label: 'Spur',
    value: 'Spur',
  },
  {
    label: 'Square',
    value: 'Square',
  },
  {
    label: 'Stairs',
    value: 'Stairs',
  },
  {
    label: 'State highway',
    value: 'State highway',
  },
  {
    label: 'Steps',
    value: 'Steps',
  },
  {
    label: 'Strand',
    value: 'Strand',
  },

  {
    label: 'Strip',
    value: 'Strip',
  },
  {
    label: 'Subway',
    value: 'Subway',
  },
  {
    label: 'Tarn',
    value: 'Tarn',
  },
  {
    label: 'Terrace',
    value: 'Terrace',
  },
  {
    label: 'Thoroughfare',
    value: 'Thoroughfare',
  },
  {
    label: 'Tollway',
    value: 'Tollway',
  },
  {
    label: 'Top',
    value: 'Top',
  },
  {
    label: 'Tor',
    value: 'Tor',
  },
  {
    label: 'Towers',
    value: 'Towers',
  },
  {
    label: 'Track',
    value: 'Track',
  },
  {
    label: 'Trail',
    value: 'Trail',
  },
  {
    label: 'Trailer',
    value: 'Trailer',
  },
  {
    label: 'Triangle',
    value: 'Triangle',
  },
  {
    label: 'Trunkway',
    value: 'Trunkway',
  },
  {
    label: 'Turn',
    value: 'Turn',
  },
  {
    label: 'Underpass',
    value: 'Underpass',
  },
  {
    label: 'Upper',
    value: 'Upper',
  },
  {
    label: 'Vale',
    value: 'Vale',
  },
  {
    label: 'Viaduct',
    value: 'Viaduct',
  },
  {
    label: 'View',
    value: 'View',
  },
  {
    label: 'Villas',
    value: 'Villas',
  },
  {
    label: 'Vista',
    value: 'Vista',
  },
  {
    label: 'Wade',
    value: 'Wade',
  },
  {
    label: 'Walk',
    value: 'Walk',
  },
  {
    label: 'Walkway',
    value: 'Walkway',
  },
  {
    label: 'Way',
    value: 'Way',
  },
  {
    label: 'Wharf',
    value: 'Wharf',
  },
  {
    label: 'Wynd',
    value: 'Wynd',
  },
  {
    label: 'Yard',
    value: 'Yard',
  },
];

// Taken from:
// https://en.wikipedia.org/wiki/Postcodes_in_Australia#Allocation
export const STATE_POSTCODES: { [state: string]: Array<[number, number]> } = {
  NSW: [
    [2000, 2599],
    [2618, 2899],
    [2921, 2999],
  ],
  ACT: [
    [200, 299],
    [2600, 2618],
    [2620, 2620], // BEARD, HUME, OAKS ESTATE, THARWA (Not included in most lists)
    [2900, 2920],
  ],
  VIC: [[3000, 3999]],
  QLD: [[4000, 4999]],
  SA: [[5000, 5799]],
  WA: [[6000, 6797]],
  TAS: [[7000, 7799]],
  NT: [[800, 899]],
};
