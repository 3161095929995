import { RouteComponentProps } from '@reach/router';
import { Box, Stack, Text } from '@spaceship-fspl/components';
import React from 'react';

import { PageContainer } from '../components/layouts/page';

export const MoneyDeposit: React.FC<RouteComponentProps> = () => {
  return (
    <PageContainer>
      <Stack spaceY="md">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Text variant={1}>Placeholder for Money Deposit Page</Text>
        </Box>
      </Stack>
    </PageContainer>
  );
};
