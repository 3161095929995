import { isAsciiString } from '@spaceship-fspl/helpers';
import { sydneyDate } from '@spaceship-fspl/helpers';
import { differenceInYears } from 'date-fns';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Message } from 'react-hook-form';

import { fromAussieDate } from './format';

// Taken from:
// https://en.wikipedia.org/wiki/Postcodes_in_Australia#Allocation
const STATE_POSTCODES: { [state: string]: Array<[number, number]> } = {
  NSW: [
    [2000, 2599],
    [2618, 2899],
    [2921, 2999],
  ],
  ACT: [
    [200, 299],
    [2600, 2618],
    [2620, 2620], // BEARD, HUME, OAKS ESTATE, THARWA (Not included in most lists)
    [2900, 2920],
  ],
  VIC: [[3000, 3999]],
  QLD: [[4000, 4999]],
  SA: [[5000, 5799]],
  WA: [[6000, 6797]],
  TAS: [[7000, 7799]],
  NT: [[800, 899]],
};

export const DEFAULT_COUNTRY_CODE = 'AU';

const MIN_AGE = 16;
const MAX_AGE = 120;

export const commonValidationRules = {
  password: {
    required: 'Password is required',
    pattern: {
      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      message: 'Your password doesn’t meet the password requirements',
    },
  },
  date: {
    pattern: {
      value: /\d{2}-\d{2}-\d{4}/,
      message: 'Date must be in format DD-MM-YYYY',
    },
  },
  postcode: {
    validate: (state: string, value: string): boolean | string => {
      const postcode = parseInt(value);

      const postcodeRanges = STATE_POSTCODES[state];
      if (postcodeRanges) {
        const valid = postcodeRanges.some(
          ([low, high]) => postcode >= low && postcode <= high,
        );

        return valid || 'Postcode not valid for state';
      }

      return 'Postcode not valid for state';
    },
  },
  phoneNumber: {
    validate: (
      value: string,
      restrictToAustralia: boolean,
    ): boolean | string => {
      const phoneNumber = parsePhoneNumberFromString(
        value,
        DEFAULT_COUNTRY_CODE,
      );

      if (!(phoneNumber && phoneNumber.isValid())) {
        return 'Invalid phone number';
      }

      if (restrictToAustralia && phoneNumber.country !== 'AU') {
        return 'Australian phone number is required';
      }

      return true;
    },
  },
  taxFileNumber: {
    required: 'Tax file number is required',
    validate: (value: string): boolean | string => {
      const tfn = value.toString();
      const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
      if (!tfn || tfn.length !== 9) {
        return 'Tax file number is invalid';
      }
      let total = 0;
      for (let i = 0; i < tfn.length; i += 1) {
        const digitAtPos = Number(tfn.charAt(i));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        total += weights[i]! * digitAtPos;
      }
      if (total === 0 || total % 11 !== 0) {
        return 'Tax file number is invalid';
      }
      return true;
    },
  },
  atLeastOneCent: {
    validate: (value: string, isPrefixed = true): boolean | string => {
      value = value.replace(/,/g, '');
      const amount =
        isPrefixed && value.startsWith('$')
          ? Number(value.slice(1))
          : Number(value);

      if (isNaN(amount)) {
        return 'Amount is invalid';
      }

      if (amount < 0.01) {
        return 'Amount must be at least $0.01';
      }

      return true;
    },
  },
  name: (
    fieldName: string,
  ): {
    required: Message | boolean;
    validate: (value: string) => Message | boolean;
  } => ({
    ...requiredValidation(fieldName),
    validate: (value: string): boolean | string => {
      if (!isAsciiString(value)) {
        return `${fieldName} contains invalid characters`;
      }
      return true;
    },
  }),
};

export const voyagerValidationRules = {
  dateOfBirth: {
    validate: (value?: string): boolean | string => {
      if (!value) {
        return false;
      }
      const dob = fromAussieDate(value);
      const age = differenceInYears(sydneyDate(), dob);

      if (age < MIN_AGE) {
        return `You must be at least ${MIN_AGE} years old to sign up to Spaceship`;
      }
      if (age > MAX_AGE) {
        return `Are you sure you're over ${MAX_AGE}?`;
      }
      return true;
    },
  },
};

export const mfaValidationRules = {
  code: {
    required: 'A code is required to proceed',
    minLength: {
      value: 6,
      message: 'Code must be 6 characters',
    },
    maxLength: {
      value: 6,
      message: 'Code must be 6 characters',
    },
    pattern: {
      value: /^[0-9]{6}$/,
      message: 'Code must be a 6 digit number',
    },
  },
};

export const superValidationRules = {
  dateOfBirth: {
    validate: (value?: string): boolean | string => {
      if (!value) {
        return false;
      }

      const dob = fromAussieDate(value);
      const age = differenceInYears(sydneyDate(), dob);

      if (age > MAX_AGE) {
        return `Are you sure you're over ${MAX_AGE}?`;
      }
      return true;
    },
  },
};

export const requiredValidation = (
  fieldName: string,
): { required: string } => ({
  required: `${
    fieldName.charAt(0).toUpperCase() + fieldName.substring(1)
  } is required`,
});
