import { useApolloClient } from '@apollo/client';
import {
  Location,
  navigate,
  Redirect,
  RouteComponentProps,
  Router,
  useLocation,
} from '@reach/router';
import {
  Status as AuthStatus,
  useCanReadSuper,
  useIsAuthenticated,
  useIsUserWithNoProduct,
  useStatus,
} from '@spaceship-fspl/auth';
import { getBoolean } from '@spaceship-fspl/dynamic-config';
import { VoyagerTopNavigation } from 'components/navigation/voyager-top-navigation';
import { BankAccountVerificationProvider } from 'contexts/bank-account-verification';
import { IdentityProvider } from 'contexts/identity';
import { FeatureFlagKeys } from 'helpers/dynamic-config';
import { loadAllTreatments } from 'helpers/experimentation';
import { BankAccount } from 'pages/bank-account';
import { BankAccount2FA } from 'pages/bank-account-2fa';
import { BankAccountLogin } from 'pages/bank-account-login';
import { BankAccountManual } from 'pages/bank-account-manual';
import { BankAccountSelectAccount } from 'pages/bank-account-select-account';
import { BankAccountSelectMethod } from 'pages/bank-account-select-method';
import { BankAccountSuccess } from 'pages/bank-account-success';
import { PageNotFound } from 'pages/page-not-found';
import { Routes } from 'pages/routes';
import React, { memo, useEffect, useRef, useState } from 'react';

export const FeatureFlaggedRoute: React.FC<
  React.PropsWithChildren<
    RouteComponentProps & {
      flag: FeatureFlagKeys;
      route?: React.FunctionComponent<
        React.PropsWithChildren<RouteComponentProps>
      >;
    }
  >
> = ({ children, flag, route: Route, ...routeComponentProps }) => {
  const apolloClient = useApolloClient();
  const [isEnabled, setIsEnabled] = useState<boolean>();

  useEffect(() => {
    let mounted = true;
    (async () => {
      const isEnabled = await getBoolean(apolloClient, flag, false);
      if (mounted) {
        setIsEnabled(isEnabled);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [apolloClient, flag]);

  // Wait for dynamic config to have loaded before deciding to show/redirect
  // otherwise a fresh load of a feature flagged route will always hit the
  // redirect.
  if (isEnabled === undefined) {
    return null;
  }

  if (isEnabled) {
    if (Route) {
      return <Route {...routeComponentProps} />;
    }

    return <>{children}</>;
  }

  return <Redirect to="/" noThrow />;
};

export const NestedRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => <>{children}</>;

export const BankAccountRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = memo(() => (
  <BankAccountVerificationProvider>
    <Router>
      <BankAccount path="/" />
      <BankAccountLogin path="login" />
      <BankAccount2FA path="2fa" />
      <BankAccountSelectMethod path="select-method" />
      <BankAccountSelectAccount path="select-account" />
      <BankAccountManual path="manual" />
      <BankAccountSuccess path="success" />
      <PageNotFound default={true} />
    </Router>
  </BankAccountVerificationProvider>
));

BankAccountRoutes.displayName = 'BankAccountRoutes';

const IGNORE_REDIRECT_PATHS = [
  /\/$/,
  /\/account\/change-password\/*/,
  /\/account\/details\/*/,
  /\/onboarding\/voyager\/*/,
  /\/signup\/super\/onboarding\/*/,
];

export const AuthenticatedRoutes: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = memo(({ children }) => {
  const [areTreatmentsLoaded, setAreTreatmentsLoaded] = useState(false);
  const status = useStatus();
  const client = useApolloClient();
  const { pathname } = useLocation();
  const redirectPath = IGNORE_REDIRECT_PATHS.some(
    (regex) => !!pathname.match(regex),
  )
    ? null
    : pathname;

  useEffect(() => {
    let mounted = true;
    if (status === AuthStatus.UNAUTHENTICATED) {
      navigate(
        redirectPath
          ? `${Routes.LOGIN}?redirect=${redirectPath}`
          : Routes.LOGIN,
        { replace: true },
      );
    } else if (status === AuthStatus.AUTHENTICATED) {
      (async () => {
        try {
          await loadAllTreatments(client);
        } catch {
          // ignore
        } finally {
          if (mounted) {
            setAreTreatmentsLoaded(true);
          }
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [client, status, redirectPath]);

  return status === AuthStatus.AUTHENTICATED && areTreatmentsLoaded ? (
    <IdentityProvider>{children}</IdentityProvider>
  ) : null;
});

AuthenticatedRoutes.displayName = 'AuthenticatedRoutes';

export function withVoyagerTopNavigation() {
  return function <Props>(
    Component: React.ComponentType<React.PropsWithChildren<Props>>,
  ): React.FC<React.PropsWithChildren<Props>> {
    return function WithVoyagerTopNavigation(props) {
      return (
        <>
          <VoyagerTopNavigation {...props} />
          <Component {...props} />
        </>
      );
    };
  };
}

withVoyagerTopNavigation.displayName = 'withVoyagerTopNavigation';

export function navigateToLogInWhenUnauthenticated() {
  return function <Props>(
    Component: React.ComponentType<React.PropsWithChildren<Props>>,
  ): React.FC<React.PropsWithChildren<Props>> {
    return function NavigateToLogInWhenUnauthenticated(props) {
      return (
        <AuthenticatedRoutes>
          <Component {...props} />
        </AuthenticatedRoutes>
      );
    };
  };
}

export function navigateAwayWhenAuthenticated() {
  return function <Props>(
    Component: React.ComponentType<React.PropsWithChildren<Props>>,
  ): React.FC<React.PropsWithChildren<Props>> {
    return function NavigateAwayWhenAuthenticated(props) {
      const isAuthenticated = useIsAuthenticated();
      const canReadSuper = useCanReadSuper();
      const isUserWithNoProduct = useIsUserWithNoProduct();

      useEffect(() => {
        if (isAuthenticated) {
          if (isUserWithNoProduct) {
            navigate(Routes.RESUME_ONBOARDING);
          } else if (canReadSuper) {
            navigate(Routes.SUPER_DASHBOARD);
          } else {
            navigate(Routes.VOYAGER_DASHBOARD);
          }
        }
      }, [isAuthenticated, canReadSuper, isUserWithNoProduct]);

      if (isAuthenticated) {
        return null;
      }

      return <Component {...props} />;
    };
  };
}

interface OnRouteChangeProps {
  action: () => void;
  pathname: string;
}

export const OnRouteChange: React.FC<
  React.PropsWithChildren<OnRouteChangeProps>
> = ({ pathname, action }) => {
  const locationRef = useRef('');

  useEffect(() => {
    if (locationRef.current !== pathname) {
      locationRef.current = pathname;
      action();
    }
  }, [pathname, action]);

  return null;
};

export const ScrollToTopOnRouteChange = (): JSX.Element => (
  <Location>
    {({ location: { pathname } }): JSX.Element => {
      return (
        <OnRouteChange
          pathname={pathname}
          action={() => {
            window.scrollTo(0, 0);
          }}
        />
      );
    }}
  </Location>
);
