import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Status, useStatus } from '@spaceship-fspl/auth';
import { getMFARequiredPayloadFromErrors } from '@spaceship-fspl/graphql';
import { MFAVerificationModalRef } from 'components/mfa-verification-modal';
import { fetch as crossFetch } from 'cross-fetch';
import { authClient } from 'helpers/auth-client';
import { config } from 'helpers/config';
import { warmDynamicConfigCache } from 'helpers/dynamic-config';
import { useDeviceFingerprint } from 'helpers/hooks/use-device-fingerprint';
import { addRumError } from 'helpers/monitoring';
import { PersistKey, remove } from 'helpers/persist';
import React, { useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

const httpLink = createHttpLink({
  uri: `${config.api.graphqlUrl}/query`,
  fetch: crossFetch,
  credentials: config.auth.useCookies ? 'include' : undefined,
});

const authLink = setContext(async (_, { headers }) => {
  let token: string | undefined;
  try {
    token = await authClient.ensureToken();
  } catch {
    // Allow unauthenticated requests
  }
  const requestId = headers?.['x-request-id'] ?? uuidV4();

  return {
    headers: {
      ...headers,
      's8-device-id': 'web',
      's8-platform': 'WEB',
      // Prefer to use x-request-id as it can be shared with Envoy proxy, s8-request-id kept for backwards compatibility
      's8-request-id': requestId,
      'x-request-id': requestId,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const createFingerprintLink = (fingerprint: string): ApolloLink =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        's8-device-fingerprint': fingerprint,
      },
    };
  });

const errorLink = onError(
  ({ operation, graphQLErrors, networkError, forward }) => {
    const context = operation.getContext();
    const requestId = operation.getContext().headers?.['x-request-id'];
    const mfaVerificationModalRef:
      | React.RefObject<MFAVerificationModalRef>
      | undefined = context.mfaVerificationModalRef;

    if (graphQLErrors) {
      const mfaRequiredPayload = getMFARequiredPayloadFromErrors(graphQLErrors);

      if (mfaRequiredPayload) {
        return fromPromise(
          new Promise<void>((resolve, reject) => {
            mfaVerificationModalRef?.current?.showMFAVerification(
              resolve,
              () => {
                reject(new Error(mfaRequiredPayload.reason));
              },
              mfaRequiredPayload.type,
              mfaRequiredPayload.to,
            );
          }),
        ).flatMap(() => forward(operation));
      }

      graphQLErrors.map(({ message, locations, path }) =>
        addRumError({
          error: message,
          context: {
            operationName: operation.operationName,
            location: JSON.stringify(locations),
            path: path,
            requestId,
            source: 'network',
          },
        }),
      );
    }

    if (networkError) {
      // To test the issue withlog retries in EstimatedApplicationExecutionDate (DEVOPS-670)
      if (operation.operationName === 'EstimatedApplicationExecutionDate') {
        addRumError({
          error: networkError.message,
          context: {
            operationName: operation.operationName,
            variables: operation.variables,
            name: networkError.name,
            requestId,
            source: 'network',
          },
        });
      } else {
        addRumError({
          error: networkError.message,
          context: {
            operationName: operation.operationName,
            name: networkError.name,
            requestId,
            source: 'network',
          },
        });
      }
    }

    return;
  },
);

export const Provider: React.FC<
  React.PropsWithChildren<{
    mfaVerificationModalRef?: React.RefObject<MFAVerificationModalRef>;
  }>
> = ({ children, mfaVerificationModalRef }) => {
  const status = useStatus();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const fingerprint = useDeviceFingerprint();

  useEffect(() => {
    let mounted = true;
    (async () => {
      // Once we have a fingerprint,
      // create and set the Apollo client.
      if (!client && fingerprint) {
        setClient(
          new ApolloClient({
            link: errorLink
              .concat(authLink)
              .concat(createFingerprintLink(fingerprint))
              .concat(httpLink),
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                // Make sure we always get latest state from API when
                // a query is used for the first time.
                fetchPolicy: 'cache-and-network',
                // For the remainder of a query's lifecycle we're happy to
                // read already-cached data.
                nextFetchPolicy: 'cache-first',
                // Support partial responses in the case of errors.
                errorPolicy: 'all',
              },
              mutate: {
                context: { mfaVerificationModalRef },
              },
            },
          }),
        );
      }

      // Warm the cache once we have an Apollo client,
      // and have determined whether we are logged in/out.
      if (client && status !== undefined) {
        try {
          await warmDynamicConfigCache(client);
        } catch {
          // don't worry about it
        }

        if (!mounted) {
          return;
        }
      }

      // Only clear store when transitioning to
      // unauthenticated to ensure we clean up
      // sensitive information.
      if (client && status === Status.UNAUTHENTICATED) {
        await client.resetStore();
        if (mounted) {
          remove([
            PersistKey.AUTH,
            PersistKey.REFERRALS_CARD_LAST_DISMISSED,
            PersistKey.SUPER_UNIT_PRICE_TIME_SCALE,
            PersistKey.SUPER_BALANCE_TIME_SCALE,
            PersistKey.SUPER_INVESTMENT_ALLOCATIONS_SELECTION_REQUESTED,
            PersistKey.BIOMETRIC_VERIFICATION_SUCCESS_DISMISSED_UNTIL,
          ]);
        }
      }

      return () => {
        mounted = false;
      };
    })();
  }, [status, client, fingerprint, mfaVerificationModalRef]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : null;
};
